import { useState, useEffect } from "react";

import './Budget.css';

import Select from 'react-select';

import { SvgAlert, SvgArrowSearch, SvgCheked, SvgDelete, SvgEdit, SvgFilter, SvgLockClose, SvgPdf, SvgSearch, SvgSetaDown, SvgSetaRight, SvgSetaTop, SvgSignature, SvgText, SvgUpdate } from "components/SvgFile";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import PopUp_StatusBudget from "components/PopUp/StatusBudget";

import { monthList } from "fixedData";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Reg_DownloadCsv } from "services/RegisterData";

export default function Page_Budget(){
    
    const [ loading, setLoading ]           = useState(false);
    const [ search, setSearch ]             = useState('');
    const [ dataUser, setDataUser ]         = useState(GetUserData('access'));
    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('budget'));
    const [ status, setStatus ]             = useState(false);
    const [ typeOrder, setTypeOrder ]       = useState('');
    
    const [ budgetYear, setBudgetYear ]     = useState('...');
    const [ budgetMonthV, setBudgetMonthV ] = useState('...');
    const [ budgetMonthL, setBudgetMonthL ] = useState('...');

    const [ budgetStatusV, setBudgetStatusV ] = useState('...');
    const [ budgetStatusL, setBudgetStatusL ] = useState('...');

    const [ budgetTypeV, setBudgetTypeV ] = useState('...');
    const [ budgetTypeL, setBudgetTypeL ] = useState('...');

    const [ budgetSignatureV, setBudgetSignatureV ] = useState('...');
    const [ budgetSignatureL, setBudgetSignatureL ] = useState('...');

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);
    
    const [ tableTd, setTableTd ] = useState(TypeTD());

    const page         = Math.ceil(showDataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showDataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('budget', setShowDataPage);
        RegisterUserData('access', setDataUser);
    }, []);

    function SortName(type) {
        return function (a, b){
            return a[type].localeCompare(b[type]);
        }        
    }

    function AltOrder(value){
        setLoading(true);
        setTimeout(() => {
            setTypeOrder(value);
            const showDataOrder = showDataPage.sort(SortName(value));
            setShowDataPage(showDataOrder);

            setLoading(false);
        }, 100);
    }

    function TypeTD(){
        const dataTD  = [];
        const optTD   = GetDataPage('list_opt_td').filter(item => item.origin == "budget");        
        const newData = GetDataPage("access_dash").find(item => item.id == GetUserData('id')).list_table_td;
        optTD.map((elem, index)=>{
            if(newData.find(item => item.id_given == elem.id)){
                dataTD.push(elem.page);
            }
        });
        return dataTD;
    }

    function SearchInput(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('budget').forEach(item =>{
                    if(item.subject.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.nameClient.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.nameContact.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.year.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowDataPage(duplicate);

        }else if(value == ''){
            setShowDataPage(GetDataPage('budget'));
        }
        setSearch(value);
        setStatus(false);
        setBudgetYear('...');

        setBudgetMonthV('...');
        setBudgetMonthL('...');

        setBudgetStatusV('...');
        setBudgetStatusL('...');

        setBudgetTypeV('...');
        setBudgetTypeL('...');
            
        setBudgetSignatureV('...');
        setBudgetSignatureL('...');
    }

    function NewBudget(id){
        SetListPag('currentPage', 'budget_details');
        SetListPag('currentPageId', id);
    }

    function ShowSignature(){
        SetListPag('currentPage', 'budget_signature');
    }

    function ShowTextFixed(){
        SetListPag('currentPage', 'budget_textfixed');
    }

    function AlertBudget(type, statusCheck, billed, id){
        if(type == 0){
            if(billed == 'Faturado'){
                return (
                    <div data-tooltip-id={ id } data-tooltip-content="Orçamento faturado" data-tooltip-place="top">
                        <SvgCheked color="#7c9f06" className="icons"/>
                    </div>
                )
            }
            return '';
        }else if(type == 1){
            if(statusCheck == 2){
                return (
                    <div data-tooltip-id={ id } data-tooltip-content="Falta 5 dias para vencer." data-tooltip-place="top">
                        <SvgAlert color="#ff9842" className="icons"/>
                    </div>
                )
            }else if(statusCheck == 1){
                return (
                    <div data-tooltip-id={ id } data-tooltip-content="O prazo do orçamento passou de 10 dias." data-tooltip-place="top">
                        <SvgAlert color="#F00000" className="icons"/>
                    </div>
                )
            }
            return '';
        }
        return '';
    }

    function OpenBudget(type, id){
        window.open(process.env.REACT_APP_API_URL + '?project=' + type + '/' + id, '_blank');
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "budget", "type": "delete_budget", "name": value });
        SetModalState('Confirmation', true);
    }

    function AltStatusBudget(id, status, code){
        SetModalData("StatusBudget", { "id": id, "status": status, "code": code });
        SetModalState('StatusBudget', true);
    }

    function ListYear(){ 
        const newData = [];
        GetDataPage('budget').map((elem, index)=>{
            if(elem.year > 1){
                newData.push(elem.year)
            }
        });
        const options = [{ "value": "...", "label": "..." }];
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        duplicate.map((elem, index)=>{
            options.push({ "value": elem, "label": elem });
        })
        return options;    
    }

    function ListMonth(){
        const newData = [{ "value": "...", "label": "..." }];
        monthList.map((elem, index)=>{
            newData.push({ "value": elem.month, "label": elem.name });
        })
        return newData;     
    }

    function ListStatus(){ 
        const newData = [{ "value": "...", "label": "..." }];
        GetDataPage('budget_status').map((elem, index)=>{
            newData.push({ "value": elem.id, "label": elem.name });
        });
        return newData;         
    }

    function ListSignature(){
        const newData = [{ "value": "...", "label": "..." }];
        GetDataPage('budget_signature').map((elem, index)=>{
            newData.push({ "value": elem.id, "label": elem.name });
        });
        return newData;   
    }

    function SearchFilter(type){        
        if(type == 0){
            const newData = [];

            {
                GetDataPage('budget').forEach(item =>{
                    // year
                    if(budgetYear != "..."){
                        if(item.year.toLowerCase().indexOf(budgetYear.toLowerCase()) != -1){
                            // month
                            if(budgetMonthV != "..."){
                                if(item.month.toLowerCase().indexOf(budgetMonthV.toLowerCase()) != -1){
                                    // type
                                    if(budgetTypeV != "..."){
                                        if(item.type == parseInt(budgetTypeV)){
                                            // signature
                                            if(budgetSignatureV != "..."){
                                                if(item.signature == parseInt(budgetSignatureV)){
                                                    // status
                                                    if(budgetStatusV != "..."){
                                                        if(item.status == parseInt(budgetStatusV)){
                                                            newData.push(item.id);
                                                        }
                                                    }else {
                                                        newData.push(item.id);
                                                    }
                                                }
                                            }else {
                                                // status
                                                if(budgetStatusV != "..."){
                                                    if(item.status == parseInt(budgetStatusV)){
                                                        newData.push(item.id);
                                                    }
                                                }else {
                                                    newData.push(item.id);
                                                }
                                            }
                                        }
                                    }else {
                                        // signature
                                        if(budgetSignatureV != "..."){
                                            if(item.signature == parseInt(budgetSignatureV)){
                                                // status
                                                if(budgetStatusV != "..."){
                                                    if(item.status == parseInt(budgetStatusV)){
                                                        newData.push(item.id);
                                                    }
                                                }else {
                                                    newData.push(item.id);
                                                }
                                            }
                                        }else {
                                            // status
                                            if(budgetStatusV != "..."){
                                                if(item.status == parseInt(budgetStatusV)){
                                                    newData.push(item.id);
                                                }
                                            }else {
                                                newData.push(item.id);
                                            }
                                        }
                                    }
                                }
                            }else{
                                // type
                                if(budgetTypeV != "..."){
                                    if(item.type == parseInt(budgetTypeV)){
                                        // signature
                                        if(budgetSignatureV != "..."){
                                            if(item.signature == parseInt(budgetSignatureV)){
                                                // status
                                                if(budgetStatusV != "..."){
                                                    if(item.status == parseInt(budgetStatusV)){
                                                        newData.push(item.id);
                                                    }
                                                }else {
                                                    newData.push(item.id);
                                                }
                                            }
                                        }else {
                                            // status
                                            if(budgetStatusV != "..."){
                                                if(item.status == parseInt(budgetStatusV)){
                                                    newData.push(item.id);
                                                }
                                            }else {
                                                newData.push(item.id);
                                            }
                                        }
                                    }
                                }else {
                                    // signature
                                    if(budgetSignatureV != "..."){
                                        if(item.signature == parseInt(budgetSignatureV)){
                                            // status
                                            if(budgetStatusV != "..."){
                                                if(item.status == parseInt(budgetStatusV)){
                                                    newData.push(item.id);
                                                }
                                            }else {
                                                newData.push(item.id);
                                            }
                                        }
                                    }else {
                                        // status
                                        if(budgetStatusV != "..."){
                                            if(item.status == parseInt(budgetStatusV)){
                                                newData.push(item.id);
                                            }
                                        }else {
                                            newData.push(item.id);
                                        }
                                    }
                                }
                            }
                        }
                    }else {
                        // month
                        if(budgetMonthV != "..."){
                            if(item.month.toLowerCase().indexOf(budgetMonthV.toLowerCase()) != -1){
                                // type
                                if(budgetTypeV != "..."){
                                    if(item.type == parseInt(budgetTypeV)){
                                        // signature
                                        if(budgetSignatureV != "..."){
                                            if(item.signature == parseInt(budgetSignatureV)){
                                                // status
                                                if(budgetStatusV != "..."){
                                                    if(item.status == parseInt(budgetStatusV)){
                                                        newData.push(item.id);
                                                    }
                                                }else {
                                                    newData.push(item.id);
                                                }
                                            }
                                        }else {
                                            // status
                                            if(budgetStatusV != "..."){
                                                if(item.status == parseInt(budgetStatusV)){
                                                    newData.push(item.id);
                                                }
                                            }else {
                                                newData.push(item.id);
                                            }
                                        }
                                    }
                                }else {
                                    // signature
                                    if(budgetSignatureV != "..."){
                                        if(item.signature == parseInt(budgetSignatureV)){
                                            // status
                                            if(budgetStatusV != "..."){
                                                if(item.status == parseInt(budgetStatusV)){
                                                    newData.push(item.id);
                                                }
                                            }else {
                                                newData.push(item.id);
                                            }
                                        }
                                    }else {
                                        // status
                                        if(budgetStatusV != "..."){
                                            if(item.status == parseInt(budgetStatusV)){
                                                newData.push(item.id);
                                            }
                                        }else {
                                            newData.push(item.id);
                                        }
                                    }
                                }
                            }
                        }else{
                            // type
                            if(budgetTypeV != "..."){
                                if(item.type == parseInt(budgetTypeV)){
                                    // signature
                                    if(budgetSignatureV != "..."){
                                        if(item.signature == parseInt(budgetSignatureV)){
                                            // status
                                            if(budgetStatusV != "..."){
                                                if(item.status == parseInt(budgetStatusV)){
                                                    newData.push(item.id);
                                                }
                                            }else {
                                                newData.push(item.id);
                                            }
                                        }
                                    }else {
                                        // status
                                        if(budgetStatusV != "..."){
                                            if(item.status == parseInt(budgetStatusV)){
                                                newData.push(item.id);
                                            }
                                        }else {
                                            newData.push(item.id);
                                        }
                                    }
                                }
                            }else {
                                // signature
                                if(budgetSignatureV != "..."){
                                    if(item.signature == parseInt(budgetSignatureV)){
                                        // status
                                        if(budgetStatusV != "..."){
                                            if(item.status == parseInt(budgetStatusV)){
                                                newData.push(item.id);
                                            }
                                        }else {
                                            newData.push(item.id);
                                        }
                                    }
                                }else {
                                    // status
                                    if(budgetStatusV != "..."){
                                        if(item.status == parseInt(budgetStatusV)){
                                            newData.push(item.id);
                                        }
                                    }else {                                       
                                        newData.push(item.id);
                                    }
                                }
                            }
                        }
                    }
                })
            }
            
            const showData = [];
            newData.map((elem, index)=>{
                if(GetDataPage('budget').find(item => item.id == elem)){
                    showData.push(GetDataPage('budget').find(item => item.id == elem));
                }
            });
            setShowDataPage(showData);

        }else if(type == 1){
            SearchInput('');
            setStatus(false);
            setBudgetYear('...');

            setBudgetMonthV('...');
            setBudgetMonthL('...');

            setBudgetStatusV('...');
            setBudgetStatusL('...');

            setBudgetTypeV('...');
            setBudgetTypeL('...');
            
            setBudgetSignatureV('...');
            setBudgetSignatureL('...');
        }
    }

    function DownloadCsv(){
        setLoading(true);
        Reg_DownloadCsv(GetUserData("id"), ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){        
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }    

    return(
        <div className="Page_Budget">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>

            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Orçamentos
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                    <div className="" onClick={ ()=>{ setStatus(true); setSearch(''); } } data-tooltip-id="type_search" data-tooltip-content="Filtrar pesquisa" data-tooltip-place="top">
                        {
                            status == false ? 
                            <SvgFilter color="#606875" className="icons" /> :
                            <SvgArrowSearch color="#606875" className="icons" />
                        }
                    </div>
                    <Tooltip id="type_search" />
                </div>
                <div className="list_opt_alt_page">
                    <div data-tooltip-id="type_text" data-tooltip-content="Listagem de textos fixos" data-tooltip-place="top" className="" onClick={ ()=>{ ShowTextFixed() } }>
                        <SvgText color="#111827" className="icons" />
                    </div>
                    <Tooltip id="type_text" />
                    <div data-tooltip-id="type_signature" data-tooltip-content="Listagem das assinaturas" data-tooltip-place="top" className="" onClick={ ()=>{ ShowSignature() } }>
                        <SvgSignature color="#111827" className="icons" />
                    </div>
                    <Tooltip id="type_signature" />
                    <div className="new_block_text" onClick={ ()=>{ NewBudget(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            Orçamentos
                        </div>
                    </div>
                </div>
            </div>
            
            {
                status == false ? null : 
                <div className="div_filter">
                    <div className="show_page_data" style={ { marginBottom: "20px" } }>
                        <div className="list_input_data">
                            <div className="div_input">
                                <Select className="year" defaultOptions options={ ListYear() } onChange={ (e)=> { setBudgetYear(e.label); } } value={ { "value": budgetYear, "label": budgetYear } } placeholder="..." />
                                <span className="name_input">Ano</span>
                            </div>
                            <div className="div_input">
                                <Select className="month" defaultOptions options={ ListMonth() } onChange={ (e)=> { setBudgetMonthV(e.value); setBudgetMonthL(e.label); } } value={ { "value": budgetMonthV, "label": budgetMonthL } } placeholder="..." />
                                <span className="name_input">Mês</span>
                            </div>
                            <div className="div_input">
                                <Select className="budget_status" defaultOptions options={ [{ "value": "...", "label": "..." }, { "value": 0, "label": "Somente orçamento" }, { "value": 1, "label": "Com proposta" }, { "value": 2, "label": "Lead" } ] } onChange={ (e)=> { setBudgetTypeV(e.value);; setBudgetTypeL(e.label); } } value={ { "value": budgetTypeV, "label": budgetTypeL } } placeholder="..." />
                                <span className="name_input">Tipo de orçamento</span>
                            </div>
                            <div className="div_input">
                                <Select className="budget_status" defaultOptions options={ ListSignature() } onChange={ (e)=> { setBudgetSignatureV(e.value); setBudgetSignatureL(e.label); } } value={ { "value": budgetSignatureV, "label": budgetSignatureL } } placeholder="..." />
                                <span className="name_input">Assinaturas</span>
                            </div>
                            <div className="div_input">
                                <Select className="budget_status" defaultOptions options={ ListStatus() } onChange={ (e)=> { setBudgetStatusV(e.value); setBudgetStatusL(e.label); } } value={ { "value": budgetStatusV, "label": budgetStatusL } } placeholder="..." />
                                <span className="name_input">Status</span>
                            </div>
                        </div>
                        <div className="list_input_data">
                            <div className="div_input" onClick={ ()=>{ SearchFilter(0) } }>
                                <div className="new_data_page">Pesquisar</div>                            
                            </div>
                            <div className="div_input" onClick={ ()=>{ SearchFilter(1) } }>
                                <div className="new_block_text">Limpar filtro</div>                            
                            </div>
                        </div>
                    </div>
                </div>
            }
            
            <div className="">
                {
                    dataUser != 1 ? null :
                    <div className="div_download">
                        <div className="btn_download" onClick={ ()=>{ DownloadCsv() } }>
                            Download da tabela abaixo
                        </div>
                    </div>
                }
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        {
                            tableTd.length > 0 ?
                            <tr>
                                {
                                    tableTd.map((elem, index)=>{
                                        switch (elem) {
                                            case "code":
                                                return <th className="cursor_pointer" width="96" align="center" key={ index } onClick={ ()=>{ AltOrder('code') } }>
                                                    <div className="td_opt">
                                                        <div className="td_name">#</div>
                                                        {
                                                            typeOrder == "code" ? 
                                                            <div className="">
                                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                                            </div> : null
                                                        }                                                       
                                                    </div>
                                                </th>;

                                            case "status":
                                                return <th className="cursor_pointer" width="180" key={ index } onClick={ ()=>{ AltOrder('nameStatus'); } }>
                                                    <div className="td_opt">
                                                        <div className="td_name">Status</div>
                                                        {
                                                            typeOrder == "nameStatus" ? 
                                                            <div className="">
                                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                                            </div> : null
                                                        }                                                       
                                                    </div>
                                                </th>;

                                            case "client":
                                                return <th className="cursor_pointer" key={ index } onClick={ ()=>{ AltOrder('nameClient') } }>
                                                    <div className="td_opt">
                                                        <div className="td_name">Cliente</div>
                                                        {
                                                            typeOrder == "nameClient" ? 
                                                            <div className="">
                                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                                            </div> : null
                                                        }                                                       
                                                    </div>
                                                </th>;

                                            case "subject":
                                                return <th className="cursor_pointer" key={ index } onClick={ ()=>{ AltOrder('subject') } }>
                                                    <div className="td_opt">
                                                        <div className="td_name">Assunto</div>
                                                        {
                                                            typeOrder == "subject" ? 
                                                            <div className="">
                                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                                            </div> : null
                                                        }                                                       
                                                    </div>
                                                </th>;

                                            case "date_create":
                                                return <th className="cursor_pointer" width="80" key={ index } onClick={ ()=>{ AltOrder('dataRegisterBr') } }>
                                                    <div className="td_opt">
                                                        <div className="td_name">Data</div>
                                                        {
                                                            typeOrder == "dataRegisterBr" ? 
                                                            <div className="">
                                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                                            </div> : null
                                                        }                                                       
                                                    </div>
                                                </th>;

                                            case "date_start":
                                                return <th className="cursor_pointer" width="80" align="center" key={ index } onClick={ ()=>{ AltOrder('dateStartBr') } }>
                                                    <div className="td_opt">
                                                        <div className="td_name">Início evento</div>
                                                        {
                                                            typeOrder == "dateStartBr" ? 
                                                            <div className="">
                                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                                            </div> : null
                                                        }                                                       
                                                    </div>
                                                </th>;

                                            case "date_end":
                                                return <th className="cursor_pointer" width="80" align="center" key={ index } onClick={ ()=>{ AltOrder('dateEndBr') } }>
                                                    <div className="td_opt">
                                                        <div className="td_name">Término evento</div>
                                                        {
                                                            typeOrder == "dateEndBr" ? 
                                                            <div className="">
                                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                                            </div> : null
                                                        }                                                       
                                                    </div>
                                                </th>;

                                            case "signature":
                                                return <th className="cursor_pointer" width="180" align="center" key={ index } onClick={ ()=>{ AltOrder('nameSignature') } }>
                                                    <div className="td_opt">
                                                        <div className="td_name">Assinatura</div>
                                                        {
                                                            typeOrder == "nameSignature" ? 
                                                            <div className="">
                                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                                            </div> : null
                                                        }                                                       
                                                    </div>
                                                </th>;

                                            case "cash":
                                                return <th className="cursor_pointer" width="50" key={ index } onClick={ ()=>{ AltOrder('cash_total') } }>
                                                    <div className="td_opt">
                                                        <div className="td_name">Valor R$</div>
                                                        {
                                                            typeOrder == "cash_total" ? 
                                                            <div className="">
                                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                                            </div> : null
                                                        }                                                       
                                                    </div>
                                                </th>;
                                        }
                                    })
                                }
                                <th width="20" align="right">#</th>
                            </tr> : 
                            <tr>
                                <th className="cursor_pointer" width="96" onClick={ ()=>{ AltOrder('code') } }>
                                    <div className="td_opt">
                                        <div className="td_name">#</div>
                                        {
                                            typeOrder == "code" ? 
                                            <div className="">
                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                            </div> : null
                                        }                                                       
                                    </div>
                                </th>
                                <th className="cursor_pointer" width="180" onClick={ ()=>{ AltOrder('nameStatus'); } }>
                                    <div className="td_opt">
                                        <div className="td_name">Status</div>
                                        {
                                            typeOrder == "nameStatus" ? 
                                            <div className="">
                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                            </div> : null
                                        }                                                       
                                    </div>
                                </th>
                                <th className="cursor_pointer" onClick={ ()=>{ AltOrder('nameClient') } }>
                                    <div className="td_opt">
                                        <div className="td_name">Cliente</div>
                                        {
                                            typeOrder == "nameClient" ? 
                                            <div className="">
                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                            </div> : null
                                        }                                                       
                                    </div>
                                </th>
                                <th className="cursor_pointer" onClick={ ()=>{ AltOrder('subject') } }>
                                    <div className="td_opt">
                                        <div className="td_name">Assunto</div>
                                        {
                                            typeOrder == "subject" ? 
                                            <div className="">
                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                            </div> : null
                                        }                                                       
                                    </div>
                                </th>
                                <th className="cursor_pointer" width="80" onClick={ ()=>{ AltOrder('dateStartBr') } }>
                                    <div className="td_opt">
                                        <div className="td_name">Data</div>
                                        {
                                            typeOrder == "dateStartBr" ? 
                                            <div className="">
                                                <SvgSetaDown className="icons" color="#2D3C53" />
                                            </div> : null
                                        }                                                       
                                    </div>
                                </th>
                                <th width="20" align="right">#</th>
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    if(tableTd.length > 0){
                                        return(
                                            <tr key={ index }>
                                                {
                                                    tableTd.map((elem, index)=>{
                                                        switch (elem) {
                                                            case "code":
                                                                return(
                                                                    <td align="center" key={ index }>
                                                                        <div className="">#EST-{ key.code }</div>
                                                                        {
                                                                            key.version == 0 ? null : <div className="">Revisão { key.version }</div>
                                                                        }
                                                                    </td>
                                                                );

                                                            case "status":
                                                                return (
                                                                    <td key={ index }>
                                                                        {
                                                                            key.nameStatus == 'Aprovado' ? 
                                                                            <div className="btn_status" style={ { backgroundColor: key.color_status } }>
                                                                                { key.nameStatus }
                                                                            </div> : 
                                                                            <>
                                                                                <div data-tooltip-id={ "id_delete_" + key.id } data-tooltip-content="Clique aqui para alterar o status do orçamento" data-tooltip-place="top" className="btn_status" onClick={ ()=>{ AltStatusBudget(key.id, key.status, '#EST-' + key.code) } } style={ { backgroundColor: key.color_status } }>
                                                                                    { key.nameStatus }
                                                                                </div>
                                                                                <Tooltip id={ "id_status_" + key.id } />
                                                                            </>
                                                                        }
                                                                    </td>
                                                                );

                                                            case "client":
                                                                return <td key={ index }>{ key.nameClient }</td>;

                                                            case "subject":
                                                                return <td key={ index }>{ key.subject == '' ? '---' : key.subject }</td>;

                                                            case "date_create":
                                                                return <td align="center" key={ index }>{ key.dataRegisterBr == '30/11/-0001' ? '---' : key.dataRegisterBr }</td>;

                                                            case "date_start":
                                                                return <td align="center" key={ index }>{ key.dateStartBr == '30/11/-0001' ? '---' : key.dateStartBr }</td>;

                                                            case "date_end":
                                                                return <td align="center" key={ index }>{ key.dateEndBr == '30/11/-0001' ? '---' : key.dateEndBr }</td>;

                                                            case "signature":
                                                                return <td align="center" key={ index }>{ key.nameSignature }</td>;

                                                            case "cash":
                                                                return <td align="center" key={ index }>{ key.cash_total }</td>;
                                                        }
                                                    })
                                                }
                                                <td align="right">
                                                    <div className="list_opt">
                                                        <div className="" data-tooltip-id={ "id_delete_" + key.id } data-tooltip-content="Updates de venda" data-tooltip-place="top" onClick={ ()=>{ OpenBudget('budget_update', key.id) } }>
                                                            <SvgUpdate className="icons" color="#2D3C53" />
                                                        </div>
                                                        <Tooltip id={ "id_update_" + key.id } />
                                                        {
                                                            key.lock_ == 1 ? 
                                                            <SvgLockClose className="icons" color="#2D3C53" /> : null
                                                        }
                                                        {
                                                            tableTd.map((elem, index)=>{
                                                                if(elem == 'icon_notification'){
                                                                    return(
                                                                        key.dateStartBr == '30/11/-0001' ? null : 
                                                                            key.nameStatus == 'Aprovado' ?
                                                                            AlertBudget(0, key.statusCheck, key.billed, key.id) :
                                                                            AlertBudget(1, key.statusCheck, key.billed, key.id)
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        <Tooltip id={ key.id } />

                                                        {
                                                            dataUser == 1 ? 
                                                            <div data-tooltip-id={ "id_delete_" + key.id } data-tooltip-content="Deletar orçamento" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, "#EST-" + key.code) } }>
                                                                <SvgDelete color="#F00000" className="icons" />
                                                            </div> : null
                                                        }
                                                        <Tooltip id={ "id_delete_" + key.id } />
                                                        <div>
                                                            <div data-tooltip-id={ "id_show_pdf_" + key.id } data-tooltip-content="Visualizar orçamento" data-tooltip-place="top" className="icons_project" onClick={ ()=>{ OpenBudget('budget', key.id) } }>
                                                                <SvgPdf color="#2D3C53" className="icons" />
                                                            </div>
                                                        </div>
                                                        <Tooltip id={ "id_show_pdf_" + key.id } />
                                                        <div data-tooltip-id={ "id_edit_" + key.id } data-tooltip-content="Editar orçamento" onClick={ ()=>{ NewBudget(key.id) } }>
                                                            <SvgEdit color="#324d6b" className="icons"/>
                                                        </div>
                                                        <Tooltip id={ "id_edit_" + key.id } />
                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                    }else {
                                        return(
                                            <tr key={ index }>
                                                <td align="center">
                                                    <div className="">#EST-{ key.code }</div>
                                                    {
                                                        key.version == 0 ? null : <div className="">Revisão { key.version }</div>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        key.nameStatus == 'Aprovado' ? 
                                                        <div className="btn_status" style={ { backgroundColor: key.color_status } }>
                                                            { key.nameStatus }
                                                        </div> : 
                                                        <>
                                                            <div data-tooltip-id={ "id_delete_" + key.id } data-tooltip-content="Clique aqui para alterar o status do orçamento" data-tooltip-place="top" className="btn_status" onClick={ ()=>{ AltStatusBudget(key.id, key.status, '#EST-' + key.code) } } style={ { backgroundColor: key.color_status } }>
                                                                { key.nameStatus }
                                                            </div>
                                                            <Tooltip id={ "id_status_" + key.id } />
                                                        </>
                                                    }
                                                </td>
                                                <td>{ key.nameClient }</td>
                                                <td>{ key.subject == '' ? '---' : key.subject }</td>
                                                <td>{ key.dateStartBr == '30/11/-0001' ? '---' : key.dateStartBr }</td>
                                                <td align="right">
                                                    <div className="list_opt">
                                                        <div className="" data-tooltip-id={ "id_delete_" + key.id } data-tooltip-content="Updates de venda" data-tooltip-place="top" onClick={ ()=>{ OpenBudget('budget_update', key.id) } }>
                                                            <SvgUpdate className="icons" color="#2D3C53" />
                                                        </div>
                                                        <Tooltip id={ "id_update_" + key.id } />
                                                        {
                                                            key.lock_ == 1 ? 
                                                            <SvgLockClose className="icons" color="#2D3C53" /> : null
                                                        }
                                                        {
                                                            key.dateStartBr == '30/11/-0001' ? null : 
                                                                key.nameStatus == 'Aprovado' ?
                                                                AlertBudget(0, key.statusCheck, key.billed, key.id) :
                                                                AlertBudget(1, key.statusCheck, key.billed, key.id)
                                                        }
                                                        <Tooltip id={ key.id } />
                                                        {
                                                            dataUser == 1 ? 
                                                            <div data-tooltip-id={ "id_delete_" + key.id } data-tooltip-content="Deletar orçamento" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, "#EST-" + key.code) } }>
                                                                <SvgDelete color="#F00000" className="icons" />
                                                            </div> : null
                                                        }
                                                        <Tooltip id={ "id_delete_" + key.id } />
                                                        <div>
                                                            <div data-tooltip-id={ "id_show_pdf_" + key.id } data-tooltip-content="Visualizar orçamento" data-tooltip-place="top" className="icons_project" onClick={ ()=>{ OpenBudget('budget', key.id) } }>
                                                                <SvgPdf color="#2D3C53" className="icons" />
                                                            </div>
                                                        </div>
                                                        <Tooltip id={ "id_show_pdf_" + key.id } />
                                                        <div data-tooltip-id={ "id_edit_" + key.id } data-tooltip-content="Editar orçamento" onClick={ ()=>{ NewBudget(key.id) } }>
                                                            <SvgEdit color="#324d6b" className="icons"/>
                                                        </div>
                                                        <Tooltip id={ "id_edit_" + key.id } />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhum orçamento encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showDataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
            <PopUp_StatusBudget />
        </div>
    )
}