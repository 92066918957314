import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePageId, typeCookiePassw } from 'fixedData';

// Delete
export function Reg_Delete(userId, id, origin, type, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Reactivate data
export function Reg_Reactivate(type, origin, idData, userId, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', origin);
    data.append('edit_data', type);

    data.append('id', idData);
    data.append('userId', userId);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Alt position contents
export function Reg_AltPosition(type_page, idPage, id, type, order, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append('type_page', type_page);
    data.append('edit_data', 'alt_position');

    data.append('idPage', idPage);
    data.append('idContents', id);
    data.append('type', type);
    data.append('order', order);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle(type_page, response.data[type_page]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// About Chroma
export function Reg_Config(listEmail, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append('type_page', 'config_email');
    data.append('edit_data', 'edit_or_register_data');

    listEmail.forEach((element, index) => {
        data.append("email_id[]", element.id);
        data.append("email_type[]", element.type);
        data.append("email_email[]", element.email);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('config_email', response.data.config_email);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// About Chroma
export function Reg_About(homeTitle, homeType, homeFile, homeImg, servColor, servTitle, servSubtitle, servImg, servList, starColor, starTitle, starList, starFooter, clientColor, brandList, textEnd, endColor, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append('type_page', 'about_chroma');
    data.append('edit_data', 'edit_or_register_data');

    data.append("homeTitle", homeTitle);
    data.append("homeType", homeType);
    data.append("homeFile", homeFile);
    data.append("homeImg", homeImg);
    data.append("servColor", servColor);
    data.append("servTitle", servTitle);
    data.append("servSubtitle", servSubtitle);
    data.append("servImg", servImg);
    data.append("starColor", starColor);
    data.append("starTitle", starTitle);
    data.append("clientColor", clientColor);
    data.append("textEnd", textEnd);
    data.append("endColor", endColor);

    servList.forEach((element, index) => {
        data.append("serv_id[]", element.id);
        data.append("serv_title[]", element.title);
        data.append("serv_text[]", element.text);
    });

    starList.forEach((element, index) => {
        data.append("star_id[]", element.id);
        data.append("star_title[]", element.title);
        data.append("star_text[]", element.text);
        data.append("star_star[]", element.star);
    });

    starFooter.forEach((element, index) => {
        data.append("footer_id[]", element.id);
        data.append("footer_sigla[]", element.sigla);
        data.append("footer_number[]", element.number);
        data.append("footer_title[]", element.title);
    });

    brandList.forEach((element, index) => {
        data.append("brand_id[]", element.id);
        data.append("brand_title[]", element.title);
        data.append("brand_img_" + index, element.img);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('about_chroma', response.data.about_chroma);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// edit or register client
export function Reg_Client(idUser, idPage, name, logoClient, corporateName, cnpj, actingArea, customerSince, note, contact, CallbackSuccess, CallbackError){
    const data    = new FormData();
    const cookies = new Cookies();

    data.append('type_page', 'client');
    data.append('edit_data', 'edit_or_register_data');

    data.append('idUser', idUser);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('logoClient', logoClient);
    data.append('corporateName', corporateName);
    data.append('cnpj', cnpj);
    data.append('actingArea', actingArea);
    data.append('customerSince', customerSince);
    data.append('note', note);

    contact.forEach((element, index) => {
        data.append('contact_id[]', element.id);
        data.append('contact_name[]', element.name);
        data.append('contact_email[]', element.email);
        data.append('contact_phone[]', element.phone);
        data.append('contact_office[]', element.office);
        data.append('contact_file_' + index, element.file);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('client', response.data.client);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Register contact
export function Reg_NewContact(idPage, name, email, phone, position, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'client');
    data.append('edit_data', 'new_contact');

    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('phone', phone);
    data.append('position', position);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('client', response.data.client);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Edit or register tag
export function Reg_Tag(id, type, name, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'portfolio');
    data.append('edit_data', 'edit_or_register_tag');

    data.append('id', id);
    data.append('type', type);
    data.append('name', name);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('portfolio_tag', response.data.portfolio_tag);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// New proposal
export function Reg_Proposal(idUser, title, client, newClient, description, projectAdd, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append('type_page', 'portfolio');
    data.append('edit_data', 'new_proposal');

    data.append("idUser", idUser);
    data.append("title", title);
    data.append("client", client);
    data.append("newClient", newClient);
    data.append("description", description);

    projectAdd.forEach(element => {
        data.append("id_project[]", element.id);
        data.append("id_text[]", element.text);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('proposal', response.data.proposal);
        SetListDataSingle('client', response.data.client);

        CallbackSuccess();
    }).catch((error)=>{

        CallbackError();
    })
}
// Register client
export function Reg_NewClient(userId, id, origin, name, corporateName, cnpj, actingArea, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', origin);
    data.append('edit_data', 'new_client');

    data.append('userId', userId);
    data.append('idPage', id);
    data.append('origin', origin);
    data.append('name', name);
    data.append('corporateName', corporateName);
    data.append('cnpj', cnpj);
    data.append('actingArea', actingArea);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle(origin, response.data[origin]);
        SetListDataSingle('client', response.data.client);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Edit ou register portfolio
export function Reg_Portfolio(userId, idPage, project, year, colorBg, text, typeFile, file, positionX, positionY, width, height, typeCrop, showWidth, widthReal, contents, listLink, datasheet, restricted, client, listServices, listTag, coverTitle, coverSubtitle, coverText, coverColor, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'portfolio');
    data.append('edit_data', 'edit_or_new_portfolio');

    data.append("userId", userId);
    data.append("id_page", idPage);

    data.append("project", project);
    data.append("year", year);
    data.append("color_bg", colorBg);
    data.append("text", text);
    data.append("type_file", typeFile);
    data.append("file", file);

    data.append("positionX", positionX);
    data.append("positionY", positionY);
    data.append("width", width);
    data.append("height", height);
    data.append("typeCrop", typeCrop);
    data.append("showWidth", showWidth);
    data.append("widthReal", widthReal);

    data.append("datasheet", datasheet);
    data.append("restricted", restricted);
    data.append("client", client);

    data.append("cover_title", coverTitle);
    data.append("cover_subtitle", coverSubtitle);
    data.append("cover_text", coverText);
    data.append("cover_color", coverColor);
    
    // links
    listLink.forEach(element => {
        data.append("links_id[]", element.id);
        data.append("links_type[]", element.type);
        data.append("links_title[]", element.title);
        data.append("links_link[]", element.link);
    });
    
    // services
    listServices.forEach(element => {
        data.append("services_id[]", element.id);
        data.append("services_newId[]", element.id_service);
    });
    
    // Tag
    listTag.forEach(element => {
        data.append("tag_id[]", element.id);
        data.append("tag_newId[]", element.newID);
    })

    // contents
    contents.forEach((elent_0, index_0) => {
        data.append('data_id[]', elent_0.id);
        data.append('data_type[]', elent_0.type);
        data.append('data_container[]', elent_0.container);
        data.append('data_order[]', elent_0.order_);
        data.append('data_width[]', elent_0.width);
        data.append('data_height[]', elent_0.height);

        elent_0.contents.forEach((elent_1, index_1) => {
            data.append('index_0[]', index_0);
            data.append('index_1[]', index_1);
            data.append('contents_id_' + index_0 + '_' + index_1, elent_1.id);
            data.append('contents_id_content_' + index_0 + '_' + index_1, elent_1.id_content);
            data.append('contents_type_' + index_0 + '_' + index_1, elent_1.type);
            data.append('contents_width_' + index_0 + '_' + index_1, elent_1.width);
            data.append('contents_height_' + index_0 + '_' + index_1, elent_1.height);

            data.append('contents_type_video_' + index_0 + '_' + index_1, elent_1.type_video);
            data.append('contents_link_' + index_0 + '_' + index_1, elent_1.link);
            data.append('contents_title_' + index_0 + '_' + index_1, elent_1.title);
            data.append('contents_subtitle_' + index_0 + '_' + index_1, elent_1.subtitle);
            data.append('contents_file_' + index_0 + '_' + index_1, elent_1.file);
            data.append('contents_name_' + index_0 + '_' + index_1, elent_1.name);
            data.append('contents_text_' + index_0 + '_' + index_1, elent_1.text);  
            data.append('contents_text_1_' + index_0 + '_' + index_1, elent_1.text_1); 
            data.append('contents_model_gallery_' + index_0 + '_' + index_1, elent_1.model_gallery);  

            data.append('contents_data_type_crop_' + index_0 + '_' + index_1, elent_1.typeCrop);
            data.append('contents_data_positionX_' + index_0 + '_' + index_1, elent_1.positionX);
            data.append('contents_data_positionY_' + index_0 + '_' + index_1, elent_1.positionY);
            data.append('contents_data_width_' + index_0 + '_' + index_1, elent_1.crop_width);
            data.append('contents_data_height_' + index_0 + '_' + index_1, elent_1.crop_height);
            data.append('contents_data_showWidth_' + index_0 + '_' + index_1, elent_1.showWidth);
            data.append('contents_data_widthReal_' + index_0 + '_' + index_1, elent_1.widthReal);

            if(elent_1.gallery){
                data.append('gallery_qtd_' + index_0 + '_' + index_1, elent_1.gallery.length);
                elent_1.gallery.forEach((elent_2, index_2) => {                    
                    data.append('gallery_index_0_' + index_0 + '_' + index_1 + '_' + index_2, index_0);
                    data.append('gallery_index_1_' + index_0 + '_' + index_1 + '_' + index_2, index_1);

                    data.append('gallery_id_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.id);
                    data.append('gallery_title_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.title);
                    data.append('gallery_text_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.text);
                    data.append('gallery_file_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.file);
                });
            }
        });
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);

        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('portfolio', response.data.portfolio);

        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Register or new proposal
export function Reg_EditOrRegisterProposal(idPage, idUser, typeLogo, color, client, project, description, proposalStatus, showAboutCompany, meeting, newContact, newPortfolio, CallbackSuccess, CallbackError){
    const data    = new FormData();
    const cookies = new Cookies();

    data.append('type_page', 'proposal');
    data.append('edit_data', 'edit_or_new_proposal');

    data.append("id_page", idPage);
    data.append("idUser", idUser);
    data.append("typeLogo", typeLogo);
    data.append("color", color);
    data.append("client", client);
    data.append("project", project);
    data.append("description", description);
    data.append("proposalStatus", proposalStatus);
    data.append("showAboutCompany", showAboutCompany);
    data.append("meeting", meeting);

    if(newPortfolio){
        newPortfolio.forEach(element => {
            data.append("newPortfolio_id[]", element);
        });
    }
    
    if(newContact){
        newContact.forEach(element => {
            data.append("new_contact_id[]", element.id);
            data.append("new_contact_idnew[]", element.idNew);
            data.append("new_contact_type[]", element.type);
            data.append("new_contact_name[]", element.name);
            data.append("new_contact_position[]", element.position);
            data.append("new_contact_email[]", element.email);
            data.append("new_contact_phone[]", element.phone);
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('proposal', response.data.proposal);
        SetListDataSingle('client', response.data.client);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Register Portfolio
export function Reg_AddPortifolio(idUser, idProposal, idPortfolio, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'proposal');
    data.append('edit_data', 'add_portfolio');

    data.append('idUser', idUser);
    data.append('idProposal', idProposal);
    data.append('idPortfolio', idPortfolio);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('proposal', response.data.proposal);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Add portfolio in proposal
export function Reg_AddPortifolioProposal(idUser, idProposal, idPortfolio, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'proposal');
    data.append('edit_data', 'add_new_portfolio');

    data.append('idUser', idUser);
    data.append('idProposal', idProposal);

    idPortfolio.forEach(element => {
        data.append("id_portfolio[]", element);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('proposal', response.data.proposal);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Edit or register data user
export function Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, addPage, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_profile');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);
    data.append('nivel', nivel);
    data.append('note', note);

    addPage.forEach((element, index) => {
        data.append('page_value[]', element.value);
        data.append('page_name[]', element.label);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('access_dash', response.data.access_dash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        if(userId == idPage){
            const newData = response.data.access_dash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
            }

            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('name', newData['name']);
            SetUserData('email', newData['email']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// add td
export function Reg_AddTd(userId, id_td, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'add_td_page');

    data.append('userId', userId);
    data.append('id_td', id_td);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit or register category
export function Reg_Category(id, origin, name, typeFile, video, color, file, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'services_category');
    data.append('edit_data', 'edit_or_register_category');

    data.append('id', id);
    data.append('name', name);
    data.append('type_file', typeFile);
    data.append('video', video);
    data.append('color', color);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('services_category', response.data.services_category);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Register new combo
export function Reg_NewCombo(idPage, name, services, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'services_combo');
    data.append('edit_data', 'edit_or_register_combo');

    data.append('idPage', idPage);
    data.append('name', name);

    services.forEach(element => {
        data.append('id[]', element.id);
        data.append('idNew[]', element.idNew);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('services_combo', response.data.services_combo);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Edit or register services
export function Reg_Services(idPage, idUser, code, name, cash, textBudg, textPres, link, category, contents, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'services');
    data.append('edit_data', 'edit_or_register_services');

    data.append('idPage', idPage);
    data.append('idUser', idUser);
    data.append('code', code);
    data.append('name', name);
    data.append('cash', cash);
    data.append('textBudg', textBudg);

    data.append('textPres', textPres);

    link.forEach((element, index)=> {
        data.append('link_id[]', element.id);
        data.append('link[]', element.link);
    });

    category.forEach((element, index)=> {
        data.append('category_id[]', element.value);
    });

    if(contents.length > 0){
        data.append('contents_id', contents[0].id);
        data.append('contents_type', contents[0].type);
            
        data.append('type_file_1', contents[0].type_file_1);
        data.append('name_1', contents[0].name_1);
        data.append('title_1', contents[0].title_1);
        data.append('video_1', contents[0].video_1);
        data.append('img_1', contents[0].img_1);
        data.append('positionX_1', contents[0].positionX_1);
        data.append('positionY_1', contents[0].positionY_1);
        data.append('crop_width_1', contents[0].crop_width_1);
        data.append('crop_height_1', contents[0].crop_height_1);
        data.append('typeCrop_1', contents[0].typeCrop_1);
        data.append('showWidth_1', contents[0].showWidth_1);
        data.append('widthReal_1', contents[0].widthReal_1);
            
        data.append('type_file_2', contents[0].type_file_2);
        data.append('name_2', contents[0].name_2);
        data.append('title_2', contents[0].title_2);
        data.append('video_2', contents[0].video_2);
        data.append('img_2', contents[0].img_2);
        data.append('positionX_2', contents[0].positionX_2);
        data.append('positionY_2', contents[0].positionY_2);
        data.append('crop_width_2', contents[0].crop_width_2);
        data.append('crop_height_2', contents[0].crop_height_2);
        data.append('typeCrop_2', contents[0].typeCrop_2);
        data.append('showWidth_2', contents[0].showWidth_2);
        data.append('widthReal_2', contents[0].widthReal_2);
            
        data.append('type_file_3', contents[0].type_file_3);
        data.append('name_3', contents[0].name_3);
        data.append('title_3', contents[0].title_3);
        data.append('video_3', contents[0].video_3);
        data.append('img_3', contents[0].img_3);
        data.append('positionX_3', contents[0].positionX_3);
        data.append('positionY_3', contents[0].positionY_3);
        data.append('crop_width_3', contents[0].crop_width_3);
        data.append('crop_height_3', contents[0].crop_height_3);
        data.append('typeCrop_3', contents[0].typeCrop_3);
        data.append('showWidth_3', contents[0].showWidth_3);
        data.append('widthReal_3', contents[0].widthReal_3);
            
        data.append('type_file_4', contents[0].type_file_4);
        data.append('name_4', contents[0].name_4);
        data.append('title_4', contents[0].title_4);
        data.append('video_4', contents[0].video_4);
        data.append('img_4', contents[0].img_4);
        data.append('positionX_4', contents[0].positionX_4);
        data.append('positionY_4', contents[0].positionY_4);
        data.append('crop_width_4', contents[0].crop_width_4);
        data.append('crop_height_4', contents[0].crop_height_4);
        data.append('typeCrop_4', contents[0].typeCrop_4);
        data.append('showWidth_4', contents[0].showWidth_4);
        data.append('widthReal_4', contents[0].widthReal_4);
    }else {
        data.append('contents_id', "");
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('services', response.data.services);
        SetListDataSingle('services_category', response.data.services_category);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Copy Service
export function Reg_CopyService(id, idUser, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'services');
    data.append('edit_data', 'copy_services');

    data.append('id', id);
    data.append('idUser', idUser);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('services', response.data.services);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Register signature
export function Reg_Signature(userId, id, name, file, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'budget_signature');
    data.append('edit_data', 'new_or_edit_signature');

    data.append('userId', userId);
    data.append('id', id);
    data.append('name', name);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('budget_signature', response.data.budget_signature);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Register text fixed
export function Reg_TextFixed(userId, id, type, title, text, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'budget_text_fixed');
    data.append('edit_data', 'new_or_edit_text_fixed');

    data.append('userId', userId);
    data.append('id', id);
    data.append('type', type);
    data.append('title', title);
    data.append('text', text);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('budget_text_fixed', response.data.budget_text_fixed);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Edit or register budget
export function Reg_Budget(idPage, idUser, typeDiscount, discount, lock_, version, signature, typeBudget, chanceClose, proposal, client, contact, nameClient, corporateName, cnpj, actingArea, customerSince, contactName, contactEmail, contactPhone, contactOffice, subject, status, dateStatus, newStatus, newColor, billed, dueDate, motive, newMotive, obs, dateStart, dateEnd, mountingStart, mountingEnd, services, textFixed, showGroup, listCategory, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'budget');
    data.append('edit_data', 'edit_or_register_budget');

    data.append('email', cookies.get(typeCookieEmail));
    data.append('password', cookies.get(typeCookiePassw));

    data.append('idPage', idPage);
    data.append('idUser', idUser);
    data.append('typeDiscount', typeDiscount);
    data.append('discount', discount);
    data.append('lock_', lock_);
    data.append('version', version);
    data.append('signature', signature);
    data.append('typeBudget', typeBudget);
    data.append('chanceClose', chanceClose);
    data.append('proposal', proposal);
    data.append('client', client);
    data.append('contact', contact);
    
    data.append('nameClient', nameClient);
    data.append('corporateName', corporateName);
    data.append('cnpj', cnpj);
    data.append('actingArea', actingArea);
    data.append('customerSince', customerSince);
    data.append('contactName', contactName);
    data.append('contactEmail', contactEmail);
    data.append('contactPhone', contactPhone);
    data.append('contactOffice', contactOffice);

    data.append('subject', subject);
    data.append('status', status);
    data.append('dateStatus', dateStatus);
    data.append('newStatus', newStatus);
    data.append('newColor', newColor);
    data.append('billed', billed);
    data.append('dueDate', dueDate);
    data.append('motive', motive);
    data.append('newMotive', newMotive);
    data.append('obs', obs);
    data.append('dateStart', dateStart);
    data.append('dateEnd', dateEnd);
    data.append('mountingStart', mountingStart);
    data.append('mountingEnd', mountingEnd);
    
    data.append('showGroup', showGroup);

    listCategory.forEach(element => {
        console.log(element);
        data.append('category_id[]', element.id);
        data.append('category_idCategory[]', element.id_category);
        data.append('category_new[]', element.new_category);
    });

    textFixed.forEach(element => {
        data.append('textFixed_id[]', element.id);
        data.append('textFixed_title[]', element.title);
        data.append('textFixed_text[]', element.text);
    });

    services.forEach((element, index) => {
        data.append('service_id[]', element.id);
        data.append('service_id_edit[]', element.id_edit);
        data.append('service_title[]', element.title);

        element.list_services.forEach((element_1, index_1) =>{

            element_1.service.forEach((element_2, index_2) =>{
                data.append('service_data_index_0[]', index);
                data.append('service_data_index_1[]', index_2);

                data.append('service_data_index[]', index);
                data.append('service_data_id[]', element_2.id);
                data.append('service_data_id_service[]', element_2.id_service);
                data.append('service_data_title[]', element_2.title);
                data.append('service_data_qtd[]', element_2.qtd);
                data.append('service_data_tax[]', element_2.tax);
                data.append('service_data_cash[]', element_2.cash);
                data.append('service_data_text[]', element_2.text);

                data.append('link_qtd[]', element_2.list_link.length);
                element_2.list_link.forEach((element_3, index_3) => {                    
                    data.append('link_index_0_' + index + '_' + index_2 + '_' + index_3, index);
                    data.append('link_index_1_' + index + '_' + index_2 + '_' + index_3, index_2);

                    data.append('link_id_' + index + '_' + index_2 + '_' + index_3, element_3.id);
                    data.append('link_type_' + index + '_' + index_2 + '_' + index_3, element_3.type);
                    data.append('link_id_portfolio_' + index + '_' + index_2 + '_' + index_3, element_3.portfolio_id);
                    data.append('link_link_' + index + '_' + index_2 + '_' + index_3, element_3.link);
                });

            });        
        })
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('client', response.data.client);
        SetListDataSingle('budget', response.data.budget);
        SetListDataSingle('budget_signature', response.data.budget_signature);
        SetListDataSingle('budget_text_fixed', response.data.budget_text_fixed);
        SetListDataSingle('budget_status', response.data.budget_status);
        SetListDataSingle('budget_historic', response.data.budget_historic);
        SetListDataSingle('budget_category', response.data.budget_category);
        SetListDataSingle('proposal', response.data.proposal);
        CallbackSuccess();
    }).catch((error)=>{

        CallbackError();
    })
}
// Alt status budget
export function Reg_AltStatusBudget(idBudget, idUser, code, status, dateStatus, newStatus, color, currentStatus, motive, newMotive, obsMotive, billed, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'budget');
    data.append('edit_data', 'alt_status_budget');

    data.append('idBudget', idBudget);
    data.append('idUser', idUser);
    data.append('code', code);
    data.append('status', status);
    data.append('dateStatus', dateStatus);
    data.append('newStatus', newStatus);
    data.append('color', color);
    data.append('currentStatus', currentStatus);
    data.append('motive', motive);
    data.append('newMotive', newMotive);
    data.append('obsMotive', obsMotive);
    data.append('billed', billed);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('budget', response.data.budget);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Alt open budget
export function Reg_OpenBudget(idBudget, idUser, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'budget');
    data.append('edit_data', 'open_budget');

    data.append('idBudget', idBudget);
    data.append('idUser', idUser);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('budget', response.data.budget);
        SetListDataSingle('budget_historic', response.data.budget_historic);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Alt version budget
export function Reg_AltVersionBudget(idBudget, version, userId, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'budget');
    data.append('edit_data', 'alt_version_budget');

    data.append('idBudget', idBudget);
    data.append('version', version);
    data.append('userId', userId);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('budget', response.data.budget);
        SetListDataSingle('budget_historic', response.data.budget_historic);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Copy Budget
export function Reg_CopyBudget(id, idUser, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'budget');
    data.append('edit_data', 'copy_budget');

    data.append('id', id);
    data.append('idUser', idUser);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListDataSingle('budget', response.data.budget);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Dowonload csv
export function Reg_DownloadCsv(idUser, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'download_csv');

    data.append('idUser', idUser);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/downloadCsv.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {   
        console.log(response.data); 
        CallbackSuccess();
        
        let url = process.env.REACT_APP_API_URL + 'php/budget_table.csv';
        let link = document.createElement('a');
        link.href = url;

        link.download = 'budget_table.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }).catch((error)=>{
        CallbackError();
    })
}
// budget - Tasks checked
export function Reg_TasksChecked(userId, id, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'budget');
    data.append('edit_data', 'tasks_checked');

    data.append('userId', userId);
    data.append('id', id);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {   
        console.log(response.data); 
        SetListDataSingle('budget_task', response.data.budget_task);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Updates
export function Reg_Updates(userId, idPage, linkMonday, folderDrive, gestResp, directionCreation, text, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'budget');
    data.append('edit_data', 'updates');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('link_monday', linkMonday);
    data.append('folder_drive', folderDrive);
    data.append('gest_resp', gestResp);
    data.append('direction_creation', directionCreation);
    data.append('text', text);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {   
        console.log(response.data); 
        SetListDataSingle('budget_update', response.data.budget_update);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Upadates emails
export function Reg_UpdatesEmail(userId, idPage, subject, listEmail, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_data', 'updates');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('subject', subject);
    listEmail.forEach(element => {
        data.append('email[]', element.email);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/send_email_updates.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {   
        console.log(response.data.budget_update_email); 
        switch (response.data.type) {
            case "Email enviado com sucesso":
                    SetListDataSingle('budget_update_email', response.data.budget_update_email);
                    CallbackSuccess();
                break;
        
            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

// Add Tasks
export function Reg_Tasks(type, userId, idPage, responsible, start, end, priority, title, text, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'list_tasks');
    data.append('edit_data', 'tasks');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('type', type);
    data.append('start', start);
    data.append('end', end);
    data.append('priority', priority);
    data.append('title', title);
    data.append('text', text);

    responsible.forEach((elem, index) => {
        data.append('id_responsible[]', elem.value);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {   
        console.log(response.data); 
        SetListDataSingle('list_tasks', response.data.list_tasks);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit user logado
export function Reg_EditUser(id, name, email, pass, file, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_user');

    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    console.log(file);
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        const newData = response.data.access_dash.find(item => item.id == id);
        cookies.set(typeCookieEmail, email, '/', cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
        }
        SetUserData('id', newData['id']);
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Tasks
export function Reg_MenuTasks(userId, id, typeTasks, responsible, start, finalDate, priority, dataId, title, text, status, motive, feedback, newStart, newEnd, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'list_tasks');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('id', id);
    data.append('type', typeTasks);
    data.append('start', start);
    data.append('finalDate', finalDate);
    data.append('priority', priority);
    data.append('dataId', dataId);
    data.append('title', title);
    data.append('text', text);
    data.append('status', status);
    data.append('motive', motive);
    data.append('feedback', feedback);
    data.append('newStart', newStart);
    data.append('newEnd', newEnd);

    if(responsible.length > 0){
        responsible.forEach((elem, index) => {
            data.append('id_responsible[]', elem.value);
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {   
        console.log(response.data); 
        SetListDataSingle('list_tasks', response.data.list_tasks);
        CallbackSuccess();

    }).catch((error)=>{
        console.log(error);
        CallbackError();
    })
}
// Tasks - status busget
export function Reg_TasksStatus(userId, id, status, motive, feedback, newStart, newEnd, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'list_tasks');
    data.append('edit_data', 'alt_status');

    data.append('userId', userId);
    data.append('id', id);
    data.append('status', status);
    data.append('motive', motive);
    data.append('feedback', feedback);
    data.append('newStart', newStart);
    data.append('newEnd', newEnd);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {   
        console.log(response.data); 
        SetListDataSingle('list_tasks', response.data.list_tasks);
        CallbackSuccess();

    }).catch((error)=>{
        console.log(error);
        CallbackError();
    })
}