import { useState, useEffect, useRef } from "react";

import './Details.css';

import Cookies from 'universal-cookie';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config, configText, typeCookiePageId } from "fixedData";

import { SvgAddData, SvgBehance, SvgDelete, SvgEdit, SvgEye, SvgGoogleDrive, SvgImg, SvgLink_opt2, SvgLockClose, SvgOpt_1, SvgOpt_10, SvgOpt_11, SvgOpt_12, SvgOpt_2, SvgOpt_3, SvgOpt_4, SvgOpt_5, SvgOpt_6, SvgOpt_7, SvgOpt_8, SvgOpt_9, SvgReturnCircle, SvgSave, SvgSearch, SvgSetaBottom, SvgSetaDown, SvgSetaRight, SvgSetaTop, SvgSetaUp, SvgSite, SvgText, SvgText_Center, SvgText_Right, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_AltPosition, Reg_Portfolio } from "services/RegisterData";

import PopUp_NewTag from "components/PopUp/NewTag";
import PopUP_NewClient from "components/PopUp/NewClient";
import PopUp_PhotoCrop from "components/PopUp/PhotoCrop";

import Toast from "components/Toast";

export default function Page_Portfolio_Details(){

    const cookies                 = new Cookies();
    const [ loading, setLoading ] = useState(false);
    const [ toast, setToast ]     = useState(false);

    const [ userId, setUserId ] = useState(GetUserData('id'));

    const [ showClient, setShowClient ]     = useState(GetDataPage('client'));
    const [ showServices, setShowServices ] = useState(GetDataPage('services'));

    const [ portfolio, setPortfolio ] = useState(GetDataPage('portfolio'));
    const [ showTag, setShowTag ]     = useState(GetDataPage('portfolio_tag'));
    const [ idPage, setIdPage ]       = useState(GetListPag('currentPageId'));
    
    const [ url, setUrl ]         = useState(InitialData('url'));
    const [ project, setProject ] = useState(InitialData('project'));
    const [ year, setYear ]       = useState(InitialData('year'));
    const [ colorBg, setColorBg ] = useState(InitialData('color_bg'));
    const [ text, setText ]       = useState(InitialData('text'));

    const [ typeFile, setTypeFile ]   = useState(InitialData('type_file'));
    const [ file, setFile ]           = useState(InitialData('file'));
    const [ stateFile, setStateFile ] = useState(false);
    const [ positionX, setPositionX ] = useState('');
    const [ positionY, setPositionY ] = useState('');
    const [ width, setWidth ]         = useState('');
    const [ height, setHeight ]       = useState('');
    const [ typeCrop, setTypeCrop ]   = useState('');
    const [ showWidth, setShowWidth ] = useState('');
    const [ widthReal, setWidthReal ] = useState('');
    
    const [ titleCover, setTitleCover ]       = useState(InitialData('cover_title'));
    const [ subtitleCover, setSubtitleCover ] = useState(InitialData('cover_subtitle'));
    const [ textCover, setTextCover ]         = useState(InitialData('cover_text'));
    const [ colorCover, setColorCover ]       = useState(InitialData('cover_color'));

    const [ contents, setContents ]       = useState(InitialData('contents'));
    const [ linkDrive, setLinkDrive ]     = useState(InitialData('link_drive'));
    const [ linkBehance, setLinkBehance ] = useState(InitialData('link_behance'));
    const [ linkSite, setLinkSite ]       = useState(InitialData('link_site'));
    const [ listLink, setListLink ]       = useState(InitialData('link'));
    const [ datasheet, setDatasheet ]     = useState(InitialData('datasheet'));

    const [ restricted, setRestricted ] = useState(InitialData('restricted'));

    const [ statusClient, setStatusClient ] = useState(false);
    const [ client, setClient ]             = useState(InitialData('client'));

    const [ statusServices, setStatusServices ] = useState(false);
    const [ listServices, setListServices ]     = useState(InitialData('services'));
    
    const [ listTag, setListTag ]     = useState(InitialData('tag'));
    const [ statusTag, setStatusTag ] = useState(false);

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterDataPage('portfolio', setPortfolio);
        RegisterDataPage('portfolio_tag', setShowTag);

        RegisterDataPage("client", setShowClient);
        RegisterDataPage('services', setShowServices);

        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setShowClient(GetDataPage('client'));
        setShowServices(GetDataPage('services'));
        
        setUrl(InitialData('url'));
        setPortfolio(GetDataPage('portfolio'));
        setShowTag(GetDataPage('portfolio_tag'));
        setIdPage(GetListPag('currentPageId'));
        
        setProject(InitialData('project'));
        setYear(InitialData('year'));
        setColorBg(InitialData('color_bg'));
        setText(InitialData('text'));

        setTypeFile(InitialData('type_file'));
        setFile(InitialData('file'));
        setStateFile(false);
        setPositionX('');
        setPositionY('');
        setWidth('');
        setHeight('');
        setTypeCrop('');
        setShowWidth('');
        setWidthReal('');
        
        setTitleCover(InitialData('cover_title'));
        setSubtitleCover(InitialData('cover_subtitle'));
        setTextCover(InitialData('cover_text'));
        setColorCover(InitialData('cover_color'));
        
        setContents(InitialData('contents'));
        setLinkDrive(InitialData('link_drive'));
        setLinkBehance(InitialData('link_behance'));
        setLinkSite(InitialData('link_site'));
        setListLink(InitialData('link'));        
        setDatasheet(InitialData('datasheet'));
        
        setRestricted(InitialData('restricted'));

        setStatusClient(false);
        setClient(InitialData('client'));
        
        setStatusServices(false);
        setListServices(InitialData('services'));
        
        setStatusTag(false);
        setListTag(InitialData('tag'));
    }, [idPage, portfolio]);

    function InitialData(type){
        if(idPage !=0){
            const newData = portfolio.find(item => item.id == idPage);
            if(type == 'client'){
                const dataClient = showClient.find(item => item.id == newData['client']);
                if(dataClient){
                    let statusClient = "";
                    if(dataClient.status == 0){
                        statusClient = newData['name_client'] + " -- cliente desativado"
                    }
                    return { "value": dataClient.id, "label": dataClient.name + statusClient };
                }else {
                    return "";
                }
            }
            return newData[type];
        }
        if(type == 'client'){
            return { "value": 0, "label": "Nenhum cliente selecionado..." };
        }
        if(type == 'restricted'){
            return false;
        }
        if(type == 'type_file'){
            return 'Image';
        }
        if(type == 'contents' || type == 'link' || type == 'services' || type == 'tag'){
            return [];
        }
        return '';
    }

    function SearchTag(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('portfolio_tag').forEach(item =>{
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowTag(duplicate);
        }else if(value == ''){
            setShowTag(GetDataPage('portfolio_tag'));
        }
    }

    function SearchClient(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('client').forEach(item =>{
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.corporate_name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.acting_area.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.customer_since.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.last_purchase.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.note.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowClient(duplicate);
        }else if(value == ''){
            setShowClient(GetDataPage('client'));
        }
    }

    function SearchServices(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('services').forEach(item =>{
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowServices(duplicate);
        }else if(value == ''){
            setShowServices(GetDataPage('services'));
        }
    }

    function AddNewTag(id, value){
        if(listTag.find(item => item.name == value)){ }else {
            setListTag([...listTag, { "id": 0, "newID": id, "name": value }]);
        }
        setStatusTag(false);
    }

    function AddNewService(id, code, value){
        if(listServices.find(item => item.id == id)){ }else {
            setListServices([...listServices, { "id": 0, "id_service": id, "code": code, "name": value }]);
        }
        setStatusServices(false);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function AddNewLink(){
        setListLink([...listLink, { "id": 0, "type": "Others", "title": "", "link": "" }]);
    }

    function DeleteLink(index, id, value){
        const newData = [...listLink];
        if(id == 0){
            newData.splice(index, 1);
            setListLink(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_link", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function DeleteTag(id, index, value){
        const newData = [...listTag];
        if(id == 0){
            newData.splice(index, 1);
            setListTag(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_tag_portfolio", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function DeleteService(id, index, value){
        const newData = [...listServices];
        if(id == 0){
            newData.splice(index, 1);
            setListServices(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_service", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function HandleDataLink(type, index, value){
        const newData = [...listLink];
        newData[index][type] = value;
        setListLink(newData);
    }

    function OpenPortfolio(){
        window.open(process.env.REACT_APP_API_URL + "?project=portfolio/" + url, '_blank');
    }

    function RegisterCropData(img){
        if(img.type == "cover"){        
            setStateFile(true);
            setFile(img.file);
            setPositionX(img.positionX);
            setPositionY(img.positionY);
            setWidth(img.width);
            setHeight(img.height);
            setTypeCrop(img.typeCrop);
            setShowWidth(img.showWidth);
            setWidthReal(img.widthReal);

        }else {
            const newData = [...contents];
            newData[img.index]['contents'][img.index_1]['statusFile'] = true;
            newData[img.index]['contents'][img.index_1]['file']      = img.file;
            newData[img.index]['contents'][img.index_1]['positionX'] = img.positionX;
            newData[img.index]['contents'][img.index_1]['positionY'] = img.positionY;
            newData[img.index]['contents'][img.index_1]['crop_width']     = img.width;
            newData[img.index]['contents'][img.index_1]['crop_height']    = img.height;
            newData[img.index]['contents'][img.index_1]['typeCrop']  = img.typeCrop;
            newData[img.index]['contents'][img.index_1]['showWidth'] = img.showWidth;
            newData[img.index]['contents'][img.index_1]['widthReal'] = img.widthReal;
            setContents(newData);
        }
    }

    function PopUpCropCover(value){
        SetModalData('PhotoCrop', { "origin": "cover", "qtd": 1, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": RegisterCropData });
        SetModalState('PhotoCrop', true);
    }

    function AddNewBlock(){
        setContents([...contents, { "id": 0, "order_": 0, "type": 0, "container": false, "state": false, "height": "top", "width": "left", "contents": [ ] }]);
    }

    function NewTag(){
        setStatusTag(false);
        setStatusClient(false); 
        setStatusServices(false); 
        SetModalData("NewTag", { "id": idPage, "origin": "portfolio", "type": "newData", "name": "" });
        SetModalState('NewTag', true);
    }

    function NewClient(){
        setStatusTag(false);
        setStatusClient(false); 
        setStatusServices(false); 
        SetModalData("NewClient", { "id": idPage, "origin": "portfolio" });
        SetModalState('NewClient', true);
    }

    function AltAlign(index, height, width){
        const newData = [...contents];
        newData[index].height = height;
        newData[index].width  = width;
        newData[index].state  = false;
        setContents(newData);
    }
    
    function StateContainer(index, state){
        const newData = [...contents];
        newData[index].container = state;
        setContents(newData);
    }

    function NewDataContents(type, index, index_1){
        const newData = [...contents];
        newData[index].contents[index_1].type = type;
        setContents(newData);
    }

    function StateAlign(index, state){
        const newData = [...contents];
        newData[index].state = state;
        setContents(newData);
    }

    function RemuveContent(index, id){
        const newData = [...contents];
        if(id == 0){
            newData.splice(index, 1);
            setContents(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_contents", "name": "Conteúdo" });
            SetModalState('Confirmation', true);
        }
    }

    function ShowTypeAdd(index, qtd){
        const newData       = [...contents];
        newData[index].type = qtd;
        for (let i = 0; i < qtd; i++) {
            newData[index].contents.push({ "id": 0, "type": "", "width": "left", "height": "top", "file": "", "title": "", "text": "", "text_1": "", "type_video": "", "link": "", "model_gallery": "Normal", "gallery": [], "state": false, "height": "top", "width": "left" });
        }
        setContents(newData);
    }

    function AltTypeContent(index, index_1){
        const newData = [...contents];
        newData[index].contents[index_1].type = '';
        setContents(newData);
    }

    function StateAlignContent(index_0, index_1, value){
        const newData = [...contents];
        newData[index_0].contents[index_1].status = value;
        setContents(newData);
    }

    function AltAlignContent(index_0, index_1, height, width){
        const newData = [...contents];
        newData[index_0].contents[index_1].height = height;
        newData[index_0].contents[index_1].width  = width;
        newData[index_0].contents[index_1].status = false;
        setContents(newData);
    }

    function NewImgGallery(index_0, index_1){
        const newData = [...contents];
        newData[index_0]['contents'][index_1]['gallery'].push({ "id": 0, "index_0": index_0, "index_1": index_1, "title": "", "text": "", "file": "", "status": false });
        setContents(newData);
    }

    function HandleGallery(type, index_0, index_1, index_2, value){        
        const newData = [...contents];
        if(type == 'file'){
            if(value){
                newData[index_0]['contents'][index_1]['gallery'][index_2][type]     = value;
                newData[index_0]['contents'][index_1]['gallery'][index_2]['status'] = true;
            }else {                
                newData[index_0]['contents'][index_1]['gallery'][index_2][type]     = "";
                newData[index_0]['contents'][index_1]['gallery'][index_2]['status'] = false;
            }
        }else {
            newData[index_0]['contents'][index_1]['gallery'][index_2][type] = value;
        }
        setContents(newData);
    }

    function DeleteData(id, index_0, index_1, index_2, value){
        if(id == 0){  
            const newData = [...contents];
            newData[index_0]['contents'][index_1]['gallery'].splice(index_2, 1);
            setContents(newData);    
        }else {
            SetModalData("Confirmation", { "id": id, "origin": 'portfolio', "type": "delete_gallery", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function ShowDataType(type, index, index_1){
        const newData = [...contents];

        switch (type) {
            case "gallery":
                return(
                    <>
                        <div className="list_input_data">
                            <div className="div_input">
                                <select className="space_select model_gallery" onChange={ (e)=>{ HandleDataContents('model_gallery', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['model_gallery'] } required>
                                    <option value="Normal">Normal</option>
                                    <option value="Open">Aberta</option>
                                </select>
                                <span className="name_input">Tipo de galeria</span>
                            </div>
                        </div>
                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="div_subtitle_add">
                                    <div className="">Galeria</div>
                                    <div className="new_data_page" onClick={ ()=>{ NewImgGallery(index, index_1) } }>
                                        <span className="icons_menu">+</span> imagem
                                    </div>
                                </div>
                                {
                                    newData[index]['contents'][index_1]['gallery'].map((key, index_2)=>{
                                        return(
                                            <div className="div_input space_div show_data_text" key={ index_2 }>
                                                <div className="div_data_project">
                                                    <div className="div_input space_div space_top">
                                                        <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleGallery('title', index, index_1, index_2, e.target.value) } } value={ key.title } />
                                                        <span className="name_input">Título</span>
                                                    </div>
                                                    
                                                    <span className="div_input div_add_img">
                                                        <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.status == true ? "Imagem adicionada" : "Adicionar imagem*" }
                                                                <input type="file" onChange={ (e)=>{ HandleGallery('file', index, index_1, index_2, e.target.files[0]) } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                        {
                                                            key.file !='' ?
                                                                <>                                        
                                                                    <div className="delete_img_add" onClick={ ()=>{ HandleGallery('file', index, index_1, index_2, ""); } }>
                                                                        <SvgDelete className="icons" color="#ffffff" />
                                                                    </div>
                                                                    {
                                                                        key.status == true ?
                                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                            <SvgImg color="#324d6b" className="icons"/>
                                                                        </div> :
                                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "portfolio/gallery/" + key.file) } }>
                                                                            <SvgImg color="#324d6b" className="icons"/>
                                                                        </div>
                                                                    }
                                                                </>
                                                            : null
                                                        }
                                                    </span>

                                                    <div className="div_input space_icons" onClick={ ()=>{ DeleteData(key.id, index, index_1, index_2, 'Imagem') } } style={ { display: 'flex' } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                                {
                                                    newData[index]['contents'][index_1]['model_gallery'] == 'Open' ? null :
                                                    <div className="div_input">
                                                        <JoditEditor className="show_textarea" config={ config } value={ key.text ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleGallery('text', index, index_1, index_2, newContent) } />
                                                    </div>
                                                }    
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </>
                )

            case "highlight_1": case "highlight_2": case "highlight_3":
                return (
                    <>
                        <div className="div_data_project">
                            <div className="div_input space_div space_top">
                                <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('title', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['title'] } />
                                <span className="name_input">Título</span>
                            </div>
                        </div>
                        <div className="div_input">
                            <JoditEditor className="show_textarea" config={ configText } value={ newData[index]['contents'][index_1]['text'] ? newData[index]['contents'][index_1]['text'].replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, index_1, newContent) } />
                        </div>
                    </>
                );

            case "highlight_4":
                return (
                    <>
                        <div className="div_data_project">
                            <div className="div_input space_div space_top">
                                <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('title', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['title'] } />
                                <span className="name_input">Título</span>
                            </div>
                        </div>
                        <div className="div_input">
                            <JoditEditor className="show_textarea" config={ configText } value={ newData[index]['contents'][index_1]['text'] ? newData[index]['contents'][index_1]['text'].replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, index_1, newContent) } />
                        </div>
                        <div className="div_input">
                            <JoditEditor className="show_textarea" config={ configText } value={ newData[index]['contents'][index_1]['text_1'] ? newData[index]['contents'][index_1]['text_1'].replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text_1', index, index_1, newContent) } />
                        </div>
                    </>
                );
                    
            case "text":
                return (
                    <div className="div_input">
                        <JoditEditor className="show_textarea" config={ configText } value={ newData[index]['contents'][index_1]['text'] ? newData[index]['contents'][index_1]['text'].replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, index_1, newContent) } />
                    </div>
                )

            case "img":
                return(
                    <div className="div_data_project">
                        <div className="div_input space_div space_top">
                            <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['title'] } />
                            <span className="name_input">Título</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ newData[index]['contents'][index_1]['statusFile'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { newData[index]['contents'][index_1]['statusFile'] == true ? "Imagem adicionada" : "Adicionar imagem*" }
                                    <input type="file" onChange={ (e)=>{ PopUpCrop(index, index_1, e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                newData[index]['contents'][index_1]['file'] !='' ?
                                    <>                                        
                                        <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('file', index, index_1, ''); } }>
                                            <SvgDelete className="icons" color="#ffffff" />
                                        </div>
                                        {
                                            newData[index]['contents'][index_1]['statusFile'] == true ?
                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(newData[index]['contents'][index_1]['file'])) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div> :
                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "portfolio/img/" + newData[index]['contents'][index_1]['file']) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div>
                                        }
                                    </>
                                : null
                            }
                        </span>
                    </div>
                )

            case "video":
                return(
                    <>
                        <div className="div_data_project">
                            <div className="div_input space_top">
                                <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_video', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['type_video'] } required>
                                    <option value="">#</option>
                                    <option value="Vimeo">Vimeo</option>
                                    <option value="Youtube">Youtube</option>
                                    <option value="Drive">Drive</option>
                                </select>
                                <span className="name_input">Tipo</span>
                            </div>
                            <div className="div_input space_div space_top">
                                <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('title', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['title'] } />
                                <span className="name_input">Título</span>
                            </div>
                        </div>
                        <div className="div_data_project">
                            <div className="div_input space_div space_top">
                                <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('link', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['link'] ? newData[index]['contents'][index_1]['link'].replaceAll(' ', '') : "" } required />
                                <span className="name_input">Link do vídeo</span>
                            </div>
                            {
                                newData[index]['contents'][index_1]['link'] == '' ? null :
                                <div className="div_input space_top" onClick={ ()=>{ OpenFile(newData[index]['contents'][index_1]['type_video'], newData[index]['contents'][index_1]['link']) } }>
                                    {
                                        newData[index]['contents'][index_1]['type_video'] == 'Drive' ? 
                                        <SvgGoogleDrive color="#324d6b" className="show_icons_project" /> :
                                            newData[index]['contents'][index_1]['type_video'] == 'Vimeo' ?  
                                            <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                newData[index]['contents'][index_1]['type_video'] == 'Youtube' ? 
                                                <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                    }
                                </div>
                            }
                        </div>
                    </>
                )
        }
    }

    function HandleDataContents(type, index_0, index_1, value){
        const newData = [...contents];
        if(type == 'file'){
            newData[index_0].contents[index_1]['file']       = "";
            newData[index_0].contents[index_1]['statusFile'] = false;
        }else {
            newData[index_0].contents[index_1][type] = value;
        }
        setContents(newData);
    }

    function PopUpCrop(index, index_1, value){
        const newData = [...contents];
        SetModalData('PhotoCrop', { "origin": "portfolio", "qtd": newData[index]['contents'].length, "index": index, "index_1": index_1, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": RegisterCropData });
        SetModalState('PhotoCrop', true);
    }

    function AltPosition(id, type, order_){
        Reg_AltPosition('portfolio', idPage, id, type, order_, ()=>{ CallbackSuccess(); }, ()=>{ CallbackError() });
    }

    function CopyLink(){
        setToast(true);
        navigator.clipboard.writeText(process.env.REACT_APP_API_URL + "?project=portfolio/" + url);
        setTimeout(() => {
            setToast(false);
        }, 1500);
    }

    function SelectedAlignmentType(value, type){
        if(type == 'img'){
            switch (value) {
                case "left":
                    return <SvgSetaTop color="#E51D4D" className="icons" />;
                case "center":  
                    return <SvgText_Center color="#E51D4D" className="icons" />;
                case "right":  
                    return <SvgSetaBottom color="#E51D4D" className="icons" />;
            }
        }else {
            switch (value) {
                case "left":
                    return <SvgText color="#E51D4D" className="icons" />;
                case "center":  
                    return <SvgText_Center color="#E51D4D" className="icons" />;
                case "right":  
                    return <SvgText_Right color="#E51D4D" className="icons" />;
            }
        }
    }

    function ReturnPage(){
        SetListPag('currentPage', 'portfolio');
        SetListPag('currentPageId', 'remuve');
    }

    function OpenColor(){
        SetModalData('Color', { "setColorPage": setColorBg });
        SetModalState('Color', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        if(client.value !=0){
            Reg_Portfolio(userId, idPage, project, year, colorBg, text, typeFile, file, positionX, positionY, width, height, typeCrop, showWidth, widthReal, contents, listLink, datasheet, restricted, client.value, listServices, listTag, titleCover, subtitleCover, textCover, colorCover, ()=>{ CallbackSuccess(); }, ()=>{ CallbackError() });
        }else {
            CallbackMissingData();
        }
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackMissingData(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "missingData", "text": "É necessário selecionar um cliente para registrar o projeto!" });
        SetModalState('ReturnResponse', true);
    }
    
    return(
        <div className="Page_Portfolio_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            {
                toast == false ? null : <Toast />
            }
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Portfólio
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <div className="list_opt_alt_page">                        
                        {
                            idPage == 0 ? null :
                            <>                                
                                <div className="icons_project" onClick={ ()=>{ CopyLink() } }>
                                    <div className="preview_eye">
                                        <SvgLink_opt2 color="#6c757d" className="icons" />
                                    </div>
                                </div>
                                <div className="icons_project" onClick={ ()=>{ OpenPortfolio() } }>
                                    <div className="preview_eye">
                                        <SvgEye color="#E51D4D" className="icons" />
                                    </div>
                                </div>
                            </>
                        }
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>

                <div className="show_data_register">
                    <div className="data_project">
                        <div className="show_page_data" style={ { marginTop:0 } }>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setProject(e.target.value) } } value={ project } maxLength="140" required />
                                    <span className="name_input">Nome do projeto*</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" className="year" onChange={ (e)=>{ setYear(e.target.value) } } value={ year.replaceAll(/\D/g, '') } maxLength="4" required />
                                    <span className="name_input">Ano*</span>
                                </div>
                                <div className="div_input space_color" onClick={ ()=>{ OpenColor() } }>
                                    <div className="div_color" style={ { backgroundColor: colorBg } } />
                                    <span className="name_input">Cor de fundo</span>
                                </div>
                            </div>
                            {/* 
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setColorBg(e.target.value) } } value={ colorBg } maxLength="140" required />
                                    <span className="name_input">Cor da página*</span>
                                </div>                               
                            </div> 
                            */}

                            <div className="list_input_data">   
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Descrição</div>
                                    <JoditEditor className="text_editor" config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input">
                                    <select className="space_select" onChange={ (e)=>{ setTypeFile(e.target.value) } } value={ typeFile } required>
                                        <option value="">#</option>
                                        <option value="Image">Image</option>
                                        <option value="Color">Cor</option>
                                        <option value="Vimeo">Vídeo Vimeo</option>
                                        <option value="Youtube">Vídeo Youtube</option>
                                    </select>
                                    <span className="name_input">Tipo de capa</span>
                                </div>
                                {
                                    typeFile == 'Color' ? 
                                    <>
                                        <div className="div_input space_div">
                                            <input type="text" className="" maxLength="140" onChange={ (e)=>{ setColorCover(e.target.value) } } value={ colorCover } />
                                            <span className="name_input">Cor de fundo</span>
                                        </div>
                                        <div className="div_input">
                                            <div className="div_color" style={ { backgroundColor: colorCover } } />
                                        </div>
                                    </> : 
                                    typeFile == 'Image' ? null :
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ setFile(e.target.value) } } value={ file.replaceAll(' ', '') } maxLength="140" required />
                                                <span className="name_input">Link do vídeo</span>
                                            </div>
                                            {
                                                file == '' ? null :
                                                <div className="div_input space_top" onClick={ ()=>{ OpenFile(typeFile, file) } }>
                                                    {
                                                        typeFile == 'Vimeo' ?  
                                                        <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                        <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                    }
                                                </div>
                                            }
                                        </>  
                                }
                            </div>
                        </div>

                        {
                            typeFile == 'Image' ?
                                file !='' ?
                                    <div className="div_page_cover">
                                        <label className="div_page_cover_edit">
                                            <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file_edit" accept="image/*" placeholder="" title="" />
                                            <div>
                                                <SvgEdit color="#ffffff" className="icons" />
                                            </div>
                                        </label>
                                        {
                                            stateFile == true ?
                                            <div className="div_cover div_cover_img">
                                                <img alt="img" src={ URL.createObjectURL(file) } className="show_img_cover" />
                                            </div> :
                                            <div className="div_cover div_cover_img">
                                                <img alt="img" src={ "./assets/portfolio/cover/" + file } className="show_img_cover" />
                                            </div>
                                        }
                                    </div>
                                :
                                    <label>
                                        <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file" accept="image/*" placeholder="" title="" />
                                        <div className="div_cover">
                                            <div className="div_cover_icons">
                                                <SvgImg color="#CDD1D6" className="icons" />
                                            </div>
                                            <div className="div_cover_title">Adicione uma capa</div>
                                            <div className="div_cover_explication">
                                                Arraste e solte aqui ou <span className="div_cover_color">procure o arquivo</span>
                                            </div>
                                            <div className="div_cover_desc">sugestão 1920x1080</div>
                                        </div>
                                    </label>
                            : null
                        }

                        <div className="show_page_data">                            
                            <div className="list_type_data">
                                <div className="type_title">Links</div>
                                <div className="new_data_page" onClick={ ()=>{ AddNewLink(); } }><span className="icons_menu">+</span> link</div>
                            </div>
                            {
                                listLink.length > 0 ?
                                listLink.map((key, index)=>{
                                    return(
                                        <div className="list_type_service" key={ index }>
                                            <div className="list_input_data">  
                                                <div className="div_input space_top">
                                                    <select className="space_select" onChange={ (e)=>{ HandleDataLink('type', index, e.target.value) } } value={ key.type } required>
                                                        <option value="">#</option>
                                                        <option value="Site">Site</option>
                                                        <option value="Drive">Drive</option>
                                                        <option value="Others">Outros</option>
                                                        <option value="Behance">Behance</option>
                                                    </select>
                                                    <span className="name_input">Tipo</span>
                                                </div>
                                                <div className="div_input space_div">
                                                    <input type="text" onChange={ (e)=>{ HandleDataLink('title', index, e.target.value) } } value={ key.title } />
                                                    <span className="name_input">Título</span>
                                                </div>
                                                <div className="div_input" onClick={ ()=>{ DeleteLink(index, key.id, key.title) } } style={ { display: "flex" } }>
                                                    <SvgDelete color="#f00000" className="icons" />
                                                </div>
                                            </div>
                                            <div className="list_input_data">  
                                                <div className="div_input space_div">
                                                    <input type="text" onChange={ (e)=>{ HandleDataLink('link', index, e.target.value) } } value={ key.link.replaceAll(' ', '') } />
                                                    <span className="name_input">Link</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) :
                                <div className="list_input_data">
                                    <div className="explication">Nenhum link adicionado até o momento...</div>
                                </div>
                            }
                        </div>

                        <div className="show_page_data">                            
                            <div className="list_type_data">
                                <div className="type_title">Layout</div>
                            </div>
                            <div className="list_input_data">
                                {
                                    contents.map((key, index)=>{
                                        let numbContents = index + 1;
                                        if(numbContents <= 9){
                                            numbContents = 0 + "" + numbContents;
                                        }
                                        return(
                                            <div className="div_show_or_new_data" key={ index }>
                                                <div className="show_title">
                                                    <div className="title_div div_data_topic_project">
                                                        <div className="">#{ numbContents }</div>
                                                        <div className="div_switch">
                                                            <div className="">Largura total</div>
                                                            <div className="div_restricted_switch">
                                                                <label className="switch">
                                                                    <div className="switch_wrapper">
                                                                        <input type="checkbox" onChange={ (e)=>{ StateContainer(index, !key.container) } } checked={ key.container } />
                                                                        <span className="switch_button"></span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="div_switch">
                                                            <div className="type_arrow">
                                                                {
                                                                    index == 0 ? null :
                                                                    <div className="" onClick={ ()=>{ AltPosition(key.id, 'up', key.order_) } }>
                                                                        <SvgSetaUp className="icons" color="#6c757d" />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="type_arrow">
                                                                {
                                                                    (index + 1) == contents.length ? null :
                                                                    <div className="" onClick={ ()=>{ AltPosition(key.id, 'down', key.order_) } }>
                                                                        <SvgSetaDown className="icons" color="#6c757d" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="align_type_contents">
                                                        {/* 
                                                        <div className="div_align">
                                                            <div className="div_type_align" onClick={ ()=>{ StateAlign(index, !key.state) } }>
                                                                Alinhar linha
                                                                { SelectedAlignmentType(key.width) }
                                                            </div>
                                                            {
                                                                key.state == true ?
                                                                <div className="list_align">
                                                                    <div className="align">
                                                                        <div className={ key.height == "top" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'top', 'left') } } />
                                                                        <div className={ key.height == "top" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'top', 'center') } } />
                                                                        <div className={ key.height == "top" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'top', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "center" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'center', 'left') } } />
                                                                        <div className={ key.height == "center" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'center', 'center') } } />
                                                                        <div className={ key.height == "center" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'center', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "bottom" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'bottom', 'left') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'bottom', 'center') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'bottom', 'right') } } />
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }
                                                        </div> 
                                                        */}
                                                        <div className="" onClick={ ()=>{ RemuveContent(index, key.id) } }>
                                                            <SvgDelete color="#E51D4D" className="icons" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="div_data_project">
                                                    {
                                                        key.contents.length == 0 ?
                                                        <div className="add_project">
                                                            <div className="list_content">
                                                                <div className="div_add_type">
                                                                    <div className="list_opt">
                                                                        <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 1); }}>
                                                                            <SvgOpt_1 color="#CDD1D6" className="opt_icon"/>
                                                                        </div>
                                                                        <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 2); }}>
                                                                            <SvgOpt_2 color="#CDD1D6" className="opt_icon"/>
                                                                        </div>
                                                                        <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 3); }}>
                                                                            <SvgOpt_3 color="#CDD1D6" className="opt_icon"/>
                                                                        </div>
                                                                        <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 4); }}>
                                                                            <SvgOpt_4 color="#CDD1D6" className="opt_icon"/>
                                                                        </div>
                                                                        <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 5); }}>
                                                                            <SvgOpt_5 color="#CDD1D6" className="opt_icon"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <>
                                                            {
                                                                key.contents.map((key_1, index_1)=>{
                                                                    return(
                                                                        <div className={ key_1.type == '' ? "add_project show_data_project" : "add_project show_data_project show_data_min_height" } key={ index_1 }>
                                                                            <div className="list_content">
                                                                                {
                                                                                    key_1.type == '' ?
                                                                                    <div className="div_add_type">
                                                                                        {
                                                                                            key.contents.length > 1 ? null :
                                                                                            <>
                                                                                                <div className="div_opt_add" onClick={ ()=>{ NewDataContents('gallery', index, index_1) } }>
                                                                                                    <div className="">
                                                                                                        <SvgImg color="#FFFFFF" className="icons" />
                                                                                                    </div>
                                                                                                    <div className="font_size_text">Gallery</div>
                                                                                                </div>
                                                                                                <div className="div_opt_add">
                                                                                                    <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_1', index, index_1) } }>
                                                                                                        <div className="">
                                                                                                            <SvgText color="#FFFFFF" className="icons" />
                                                                                                        </div>
                                                                                                        <div className="font_size_text">Highlight 1</div>
                                                                                                    </div>
                                                                                                    <div className="show_inf_type" onClick={ ()=>{ OpenFile('showImg', 'example/text_1.jpg') } }>?</div>
                                                                                                </div>
                                                                                                <div className="div_opt_add">
                                                                                                    <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_2', index, index_1) } }>
                                                                                                        <div className="">
                                                                                                            <SvgText color="#FFFFFF" className="icons" />
                                                                                                        </div>
                                                                                                        <div className="font_size_text">Highlight 2</div>
                                                                                                    </div>
                                                                                                    <div className="show_inf_type" onClick={ ()=>{ OpenFile('showImg', 'example/text_2.jpg') } }>?</div>
                                                                                                </div>
                                                                                                <div className="div_opt_add">
                                                                                                    <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_3', index, index_1) } }>
                                                                                                        <div className="">
                                                                                                            <SvgText color="#FFFFFF" className="icons" />
                                                                                                        </div>
                                                                                                        <div className="font_size_text">Highlight 3</div>
                                                                                                    </div>
                                                                                                    <div className="show_inf_type" onClick={ ()=>{ OpenFile('showImg', 'example/text_3.jpg') } }>?</div>
                                                                                                </div>
                                                                                                <div className="div_opt_add">
                                                                                                    <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_4', index, index_1) } }>
                                                                                                        <div className="">
                                                                                                            <SvgText color="#FFFFFF" className="icons" />
                                                                                                        </div>
                                                                                                        <div className="font_size_text">Highlight 4</div>
                                                                                                    </div>
                                                                                                    <div className="show_inf_type" onClick={ ()=>{ OpenFile('showImg', 'example/text_4.jpg') } }>?</div>
                                                                                                </div>
                                                                                            </> 
                                                                                        }
                                                                                        {
                                                                                            key.contents.length > 2 ? null :
                                                                                            <>
                                                                                                <div className="div_opt_add" onClick={ ()=>{ NewDataContents('text', index, index_1) } }>
                                                                                                    <div className="">
                                                                                                        <SvgText color="#FFFFFF" className="icons" />
                                                                                                    </div>
                                                                                                    <div className="font_size_text">Texto</div>
                                                                                                </div>
                                                                                                <div className="div_opt_add" onClick={ ()=>{ NewDataContents('video', index, index_1) } }>
                                                                                                    <div className="">
                                                                                                        <SvgYoutube color="#FFFFFF" className="icons" />
                                                                                                    </div>
                                                                                                    <div className="font_size_text">Vídeo</div>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        <div className="div_opt_add" onClick={ ()=>{ NewDataContents('img', index, index_1) } }>
                                                                                            <div className="">
                                                                                                <SvgImg color="#FFFFFF" className="icons" />
                                                                                            </div>
                                                                                            <div className="font_size_text">Imagem</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="">
                                                                                        <div className="div_alt_data_contents align_icon_text">
                                                                                            <div className="" onClick={ ()=>{ AltTypeContent(index, index_1) } }>
                                                                                                <SvgReturnCircle color="#E51D4D" className="icons" />
                                                                                            </div>
                                                                                            <div className="div_align">
                                                                                                {
                                                                                                    key_1.type == 'highlight_1' ?
                                                                                                    <div className="div_selected" onClick={ ()=>{ OpenFile('showImg', 'example/text_1.jpg') } }>
                                                                                                        <div className="">Highlight 1</div>
                                                                                                        <div className="show_type_selected">?</div>
                                                                                                    </div> :
                                                                                                        key_1.type == 'highlight_2' ?
                                                                                                        <div className="div_selected" onClick={ ()=>{ OpenFile('showImg', 'example/text_2.jpg') } }>
                                                                                                            <div className="">Highlight 2</div>
                                                                                                            <div className="show_type_selected">?</div>
                                                                                                        </div> :
                                                                                                            key_1.type == 'highlight_3' ?
                                                                                                            <div className="div_selected" onClick={ ()=>{ OpenFile('showImg', 'example/text_3.jpg') } }>
                                                                                                                <div className="">Highlight 3</div>
                                                                                                                <div className="show_type_selected">?</div>
                                                                                                            </div> :
                                                                                                                key_1.type == 'highlight_4' ?
                                                                                                                <div className="div_selected" onClick={ ()=>{ OpenFile('showImg', 'example/text_4.jpg') } }>
                                                                                                                    <div className="">Highlight 4</div>
                                                                                                                    <div className="show_type_selected">?</div>
                                                                                                                </div>
                                                                                                    : null
                                                                                                }
                                                                                                {
                                                                                                    key_1.type == "gallery" || key_1.type == "video" ? null :
                                                                                                    <>                                                                                                            
                                                                                                        <div className="" onClick={ ()=>{ StateAlignContent(index, index_1, !key_1.status) } }>
                                                                                                            { SelectedAlignmentType(key_1.width == '' ? "left" : key_1.width, key_1.type) }
                                                                                                        </div>
                                                                                                        {
                                                                                                            key_1.status == true ?
                                                                                                            <div className={ key_1.type == "img" ? "list_align list_align_img" : "list_align" }>
                                                                                                                {
                                                                                                                    key_1.type == "img" ?                                                                                                             
                                                                                                                    <div className="align">
                                                                                                                        <div className={ key_1.height == "top" ? "img_0 img_top img_top_active" : "img_0 img_top" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'left') } } />
                                                                                                                        <div className={ key_1.height == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'center') } } />
                                                                                                                        <div className={ key_1.height == "bottom" ? "img_0 img_bottom img_bottom_active" : "img_0 img_bottom" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'right') } } />
                                                                                                                    </div> :
                                                                                                                    <>                                                                                                                    
                                                                                                                        <div className="align">
                                                                                                                            <div className={ key_1.height == "top" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'left') } } />
                                                                                                                            <div className={ key_1.height == "top" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'center') } } />
                                                                                                                            <div className={ key_1.height == "top" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'right') } } />
                                                                                                                        </div>
                                                                                                                        <div className="align">
                                                                                                                            <div className={ key_1.height == "center" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'left') } } />
                                                                                                                            <div className={ key_1.height == "center" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'center') } } />
                                                                                                                            <div className={ key_1.height == "center" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'right') } } />
                                                                                                                        </div>
                                                                                                                        <div className="align">
                                                                                                                            <div className={ key_1.height == "bottom" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'left') } } />
                                                                                                                            <div className={ key_1.height == "bottom" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'center') } } />
                                                                                                                            <div className={ key_1.height == "bottom" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'right') } } />
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                }
                                                                                                            </div>
                                                                                                            : null
                                                                                                        }
                                                                                                    </> 
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            ShowDataType(key_1.type, index, index_1)
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="list_input_data">
                                <div className="add_project">
                                    <div className="add_data" onClick={ ()=>{ AddNewBlock() } }>
                                        <SvgAddData color="#F00000" className="icons" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="show_page_data">
                            <div className="space_div show_data_text">
                                <div className="show_title">Ficha técnica</div>
                                <JoditEditor config={ config } value={ datasheet ? datasheet.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setDatasheet(newContent) } />
                            </div>
                        </div>
                    </div>

                    <div className="data_fixed">
                        <div className="show_page_data" style={ { marginTop:0 } }>
                            <div className="div_show_text">                            
                                <div className="div_restricted">
                                    <div className="div_restricted_lock">
                                        <SvgLockClose color="#606875" className="icons" />
                                    </div>
                                    <div className="div_restricted_title">Restrito</div>
                                    <div className="div_restricted_switch">
                                        <label className="switch">
                                            <div className="switch_wrapper">
                                                <input type="checkbox" value="true" onChange={ (e)=>{ setRestricted(!restricted) } } checked={ restricted } />
                                                <span className="switch_button"></span>
                                            </div>
                                        </label>
                                    </div>
                                </div>

                                <div className="div_show_select" style={ { marginTop: '20px' } }>
                                    <div className="div_category_title">Cliente</div>
                                    <div className="div_category_search">
                                        <div className="input_search">
                                            <SvgSearch color="#606875" className="icons_search" />
                                            <input type="text" className="search" onChange={ (e)=>{ SearchClient(e.target.value) } } onClick={ ()=>{ setStatusClient(true); setStatusServices(false); setStatusTag(false); } } placeholder="buscar..." />
                                        </div>
                                        {
                                            statusClient == false ? null :
                                            <div className="div_show_select_list_data">
                                                <div className="add_new_data">
                                                    <div className="div_show_select_name div_show_select_new_data" onClick={ ()=>{ NewClient() } } style={ { padding: 0 } }>
                                                        <span className="">+</span>
                                                        Novo cliente
                                                    </div>
                                                </div>
                                                <div className="show_data_existing">
                                                    {
                                                        showClient.map((key, index)=>{
                                                            if(client.value == key.id || key.status == 0){ }else {
                                                                return (
                                                                    <div className="div_show_select_name" key={ index } title={ key.name } onClick={ ()=>{ setStatusClient(false); setClient({ "value": key.id, "label": key.name }) } }>
                                                                        { key.name }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className="close_list_data">
                                                    <div className="div_show_select_name div_show_select_new_data div_show_select_cancel" onClick={ ()=>{ setStatusClient(false); } }>
                                                        Cancelar
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="div_category_list" style={ { height: "auto" } }>
                                        {
                                            client.value == 0 ? <div className="no_data">{ client.label }</div> :
                                            <div className="div_category_list_name_category">{ client.label }</div>
                                        }
                                    </div>
                                </div>

                                <div className="div_show_select" style={ { marginTop: '20px' } }>
                                    <div className="div_category_title">Serviços</div>
                                    <div className="div_category_search">
                                        <div className="input_search">
                                            <SvgSearch color="#606875" className="icons_search" />
                                            <input type="text" className="search" onChange={ (e)=>{ SearchServices(e.target.value) } } onClick={ ()=>{ setStatusClient(false); setStatusServices(true); setStatusTag(false); } } placeholder="buscar..." />
                                        </div>
                                        {
                                            statusServices == false ? null :
                                            <div className="div_show_select_list_data">
                                                <div className="show_data_existing">
                                                    {
                                                        showServices.map((key, index)=>{
                                                            if(listServices.find(item => item.id_service == key.id)){ }else {
                                                                return (
                                                                    <div className="div_show_select_name" key={ index } title={ key.name } onClick={ ()=>{ AddNewService(key.id, key.code, key.name); setStatusServices(false); } }>
                                                                        { key.code } - { key.name }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className="close_list_data">
                                                    <div className="div_show_select_name div_show_select_new_data div_show_select_cancel" onClick={ ()=>{ setStatusServices(false); } }>
                                                        Cancelar
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="div_category_list">
                                        {
                                            listServices.length > 0 ?
                                                listServices.map((key, index)=>{
                                                    return(
                                                        <div className="div_category_list_name_category" key={ index }>
                                                            <div className="div_category_list_name">
                                                                { key.code } - { key.name }
                                                            </div>
                                                            <div className="div_category_list_remuve" onClick={ ()=>{ DeleteService(key.id, index, key.name) } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            : 
                                            <div className="list_input_data">
                                                <div className="no_data" style={ { marginTop: 0 } }>Nenhum serviço adicionado...</div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="div_show_select" style={ { marginTop: '20px' } }>
                                    <div className="div_category_title">Tag</div>
                                    <div className="div_category_search">
                                        <div className="input_search">
                                            <SvgSearch color="#606875" className="icons_search" />
                                            <input type="text" className="search" onChange={ (e)=>{ SearchTag(e.target.value) } } onClick={ ()=>{ setStatusClient(false); setStatusServices(false); setStatusTag(true); } } placeholder="buscar..." />
                                        </div>
                                        {
                                            statusTag == false ? null :
                                            <div className="div_show_select_list_data">
                                                <div className="add_new_data">
                                                    <div className="div_show_select_name div_show_select_new_data" onClick={ ()=>{ NewTag() } }>
                                                        <span className="">+</span>
                                                        Nova tag
                                                    </div>
                                                </div>
                                                <div className="show_data_existing">
                                                    {
                                                        showTag.map((key, index)=>{
                                                            if(listTag.find(item => item.name == key.name)){ }else {
                                                                return (
                                                                    <div className="div_show_select_name div_show_select_name_data" key={ index } title={ key.name } onClick={ ()=>{ AddNewTag(key.id, key.name) } }>
                                                                        { key.name }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className="close_list_data">
                                                    <div className="div_show_select_name div_show_select_new_data div_show_select_cancel" onClick={ ()=>{ setStatusTag(false); } }>
                                                        Cancelar
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="div_category_list">
                                        {
                                            listTag.length > 0 ?
                                                listTag.map((key, index)=>{
                                                    return(
                                                        <div className="div_category_list_name_category" key={ index }>
                                                            {
                                                                key.id == 0 ?
                                                                <div className="div_category_list_name div_show_select_name_data">{ key.name }</div> :
                                                                <div className="div_category_list_name div_show_select_name_data">{ key.name }</div>
                                                            }
                                                            <div className="div_category_list_remuve" onClick={ ()=>{ DeleteTag(key.id, index, key.name) } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            : 
                                            <div className="list_input_data">
                                                <div className="no_data" style={ { marginTop: 0 } }>Nenhuma tag adicionada...</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <PopUp_NewTag />
            <PopUP_NewClient />
        </div>
    )
}