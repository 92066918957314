import React, { useState, useEffect } from "react";

import './Tasks.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgCheked, SvgClose, SvgDelete, SvgImg, SvgTasksCanceled } from "components/SvgFile";

import { GetUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_Tasks, Reg_TasksChecked } from "services/RegisterData";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function PopUP_Tasks(){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ task, setTask ]         = useState(GetDataPage('list_tasks'));
    const [ showData, setShowData ] = useState([]);
    const [ status, setStatus ]     = useState(false);
    
    const [ responsible, setResponsible ] = useState([{ "value": "" + GetUserData('id') + "", "label": GetUserData('name') }]);
    const [ start, setStart ]             = useState('');
    const [ end, setEnd ]                 = useState('');
    const [ priority, setPriority ]       = useState('');
    const [ title, setTitle ]             = useState('');
    const [ text, setText ]               = useState('');

    function InitialData(){
        const dataType = task.filter(item => item.type == "budget");
        const newData  = [];
        dataType.map((elem, index)=>{
            if(elem.id_given == 0){
                newData.push(elem);
            }
            if(elem.id_given == idPage){
                newData.push(elem);
            }
        })
        return newData;
    }

    function ShowResponsible(){
        const newData = [];
        GetDataPage('access_dash').map((elem, index)=>{
            newData.push({ "value": elem.id, "label": elem.name });
        })
        return newData;
    }

    function SaveData(event){
        event.preventDefault();
        console.log(responsible.length);
        if(responsible.length == 0){
            SetModalData('ReturnResponse', { "page": "missingData", "text": "É necessário definir ao menos um responsável pela tarefa..." });
            SetModalState('ReturnResponse', true);   
        }else {   
            setLoading(true);
            Reg_Tasks(modalData.type, userId, idPage, responsible, start, end, priority, title, text, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });   
        }     
    }

    function CallbackSuccess(){
        setStatus(false);
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setStatus(false);
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setStatus(false);
        setLoading(false);
        SetModalState('Tasks', false);
    }

    useEffect(()=>{
        RegisterModalData('Tasks', setModaldata);
        RegisterModalObserver('Tasks', setShowPopUp);

        RegisterDataPage('list_tasks', setTask);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setTask(GetDataPage('list_tasks'));
            setShowData(InitialData());
            
            setResponsible([{ "value": "" + GetUserData('id') + "", "label": GetUserData('name') }]);
            setStart('');
            setEnd('');
            setPriority('');
            setTitle('');
            setText('');
        }
    }, [showPopUp, task]);

    return (
        (showPopUp ?
            <>
                <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                    <img alt="loading" src="./assets/loading.gif" className="loading" />
                </div>
                <form className="PopUp" onSubmit={ SaveData }>
                    <div className="all popup_tasks">
                        <div className="div_data type_div">
                            <div className="title">
                                Tarefas
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">         
                                <div className="space_div add_data">
                                    <div className="data_status" onClick={ ()=>{ setStatus(!status); } }>
                                        { status ? "Cancelar": "Adicionar nova tarefa" }
                                    </div>
                                </div>
                                {
                                    status ? 
                                    <>
                                        <div className="list_input_data">
                                            <div className="div_input">
                                                <input type="date" className="" onChange={ (e)=>{ setStart(e.target.value) } } value={ start } required />
                                                <span className="name_input">Início</span>
                                            </div>
                                            <div className="div_input">
                                                <input type="date" className="" onChange={ (e)=>{ setEnd(e.target.value) } } value={ end } required />
                                                <span className="name_input">Término</span>
                                            </div>
                                            <div className="div_input">
                                                <select className="priority" onChange={ (e)=>{ setPriority(e.target.value) } } value={ priority } required>
                                                    <option value="low">Baixa</option>
                                                    <option value="average">Média</option>
                                                    <option value="high">Alta</option>
                                                </select>
                                                <span className="name_input">Prioridade</span>
                                            </div>
                                        </div>
                                        <div className="list_input_data">
                                            <div className="div_input space_div" style={ { textAlign: "left" } }>
                                                <Select className="space_div" defaultOptions options={ ShowResponsible() } onChange={ (e)=> { setResponsible(e); } } value={ responsible } placeholder="..." isMulti />
                                                <span className="name_input">Responsáveis</span>
                                            </div>
                                        </div>
                                        <div className="list_input_data space_div">
                                            <div className="div_input space_div">
                                                <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } required />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </div>
                                        <div className="list_input_data space_div">
                                            <div className="div_input space_div">
                                                <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } required />
                                            </div>
                                        </div>

                                        <button className="register_data button_btn">
                                            Cadastrar
                                        </button>
                                    </>
                                    : null
                                }

                                {
                                    showData.length > 0 ? 
                                    <table cellPadding="0" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th align="center" width="10">#</th>
                                                <th align="left" width="80">Responsável</th>
                                                <th align="left">Tarefa</th>
                                                <th align="left" width="40">Início</th>
                                                <th align="left" width="40">Término</th>
                                                <th align="center" width="20">#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showData.map((elem, index)=>{
                                                    let altStatus = false;
                                                    if(elem.responsible.find(item => item.id_responsible == userId)){
                                                        altStatus = true;
                                                    }
                                                    return(
                                                        <tr key={ index }>
                                                            <td align="center">{ index + 1 }</td>
                                                            <td align="left">
                                                                { 
                                                                    elem.responsible.map((elem, index)=>{
                                                                        return(
                                                                            <div className="" key={ index }>
                                                                                { elem.name }
                                                                            </div>
                                                                        )
                                                                    }) 
                                                                }
                                                            </td>
                                                            <td align="left" dangerouslySetInnerHTML={ { __html: elem.text ? elem.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                            <td align="left">{ elem.start_br }</td>
                                                            <td align="left">{ elem.end_br }</td>
                                                            <td align="center">
                                                                {
                                                                    elem.status == "Finalizado" ?
                                                                    <div className="" data-tooltip-id={ "task_obs_" + elem.id } data-tooltip-content="Tarefa finalizada" data-tooltip-place="top">
                                                                        <SvgCheked className="icons" color="#7c9f06" /> 
                                                                    </div> :
                                                                        elem.status == "Cancelado" ? 
                                                                    <div className="" data-tooltip-id={ "task_obs_" + elem.id } data-tooltip-content="Tarefa cancelada" data-tooltip-place="top">
                                                                        <SvgTasksCanceled className="icons" color="#F00000" /> 
                                                                    </div> :
                                                                    altStatus ? 
                                                                    <div className="" onClick={ ()=>{ SetModalData('AltStatusTasks', { "id": elem.id }); SetModalState('AltStatusTasks', true); } } data-tooltip-id={ "task_obs_" + elem.id } data-tooltip-content="Tarefa pendente" data-tooltip-place="top">
                                                                        <SvgCheked className="icons" color="#d7d7d7" /> 
                                                                    </div> :
                                                                    <div className="" data-tooltip-id={ "task_obs_" + elem.id } data-tooltip-content="Você não tem autorização para alterar o status dessa tarefa" data-tooltip-place="top">
                                                                        <SvgCheked className="icons" color="#d7d7d7" /> 
                                                                    </div>
                                                                }
                                                                <Tooltip id={ "task_obs_" + elem.id } />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    : 
                                    <div className="" style={ { marginTop: "16px" } }>Nenhuma tarefa adicionada até o momento...</div>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
