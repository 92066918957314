import { useState, useEffect } from "react";

import './BudgetUpdate.css';

import Axios from 'axios';

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SvgDownload, SvgLogoTipo, SvgSetaUp } from "components/SvgFile";

export default function Preview_BudgetUpdate(props){
    
    const [ dataPage, setDataPage ]     = useState(GetDataPage('budget'));
    const [ update, setUpdate ]         = useState(GetDataPage('budget_update'));
    const [ showData, setShowData ]     = useState(InitialData());
    const [ showUpdate, setShowUpdate ] = useState(InitialDataUpdate());

    function InitialDataUpdate(){
        if(update.find(item => item.id_budget == props.idPage)){
            const newData = update.find(item => item.id_budget == props.idPage);
            return newData;
        }
        return '';
    }

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.idPage);
        return newData;
    }

    function ShowDateBudget(dateStart, dateEnd){
        if(dateEnd == '30/11/-0001'){
            return dateStart;
        }else {
            let newDate = dateStart.split('/');
            return newDate[0] + "/" + newDate[1] + " - " + dateEnd;
        }
    }

    function ReturnIndex(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(()=>{
        RegisterDataPage('budget', setDataPage);
        RegisterDataPage('budget_update', setUpdate);

        document.title = showData.subject;
        document.body.style.backgroundColor = '#e5e5e5';        
        
        window.addEventListener("scroll", ()=>{
            if(window.scrollY >= 60){
                document.getElementById('return').classList.add('return_index');
                document.getElementById('return').classList.remove('return_close');
            }else {
                document.getElementById('return').classList.add('return_close');
                document.getElementById('return').classList.remove('return_index');
            }
        });
    }, []);

    console.log(showUpdate);

    return(
        <div className="Preview_BudgetUpdate">
            <div className="return_close" id="return" onClick={ ()=>{ ReturnIndex() } }>
                <SvgSetaUp className="icon_return" color="rgb(255 181 0)" />
            </div>
            
            <div className="div_line">
                <div className="line_ line_0" />
                <div className="line_ line_1" />
                <div className="line_ line_2" />
            </div>

            <div className="container">
                <div className="div_download">
                    <a href={ process.env.REACT_APP_API_URL + 'php/generatePdf.php?acao=update/' + props.idPage } target="_blank">
                        <div className="download">
                            <SvgDownload className="img_download" color="#3d3d3d" />
                        </div>
                    </a>
                </div>
                <div className="div_topic">
                    <div className="div_logo">
                        <div className="div_height_logo">
                            <SvgLogoTipo className="logotipo" color="#2D3C53" />
                        </div>
                    </div>
                    <div className="div_data_2">
                        <div className="div_height_title title_1">Updates de vendas</div>
                        <div className="subtitle_1_name">#EST-{ showData.code }</div>
                    </div>
                </div>

                <div className="div_data_budget">
                    <div className="">
                        <table cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td className="subtitle_1" align="left">Empresa:</td>
                                </tr>
                                <tr>
                                    <td align="left">{ showData.nameClient }</td>
                                </tr>
                                <tr>
                                    <td className="subtitle_1" align="left">Assunto:</td>
                                </tr>
                                <tr>
                                    <td align="left">{ showData.subject }</td>
                                </tr>
                                {
                                    showData.nameProject ? 
                                    <>
                                        <tr>
                                            <td className="subtitle_1" align="left">Projeto:</td>
                                        </tr>
                                        <tr>
                                            <td align="left">{ showData.nameProject }</td>
                                        </tr>
                                    </> : null
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="">
                        <table cellPadding="0" cellSpacing="0">
                            <tbody>
                                {
                                    showData.dateStartBr == "30/11/-0001" || showData.dateEndBr == "30/11/-0001" ? null :
                                    <>
                                        <tr>
                                            <td className="subtitle_1" align="left">Data do evento:</td>
                                        </tr>
                                        <tr>
                                            <td align="left">{ ShowDateBudget(showData.dateStartBr, showData.dateEndBr) }</td>
                                        </tr>
                                    </> 
                                }
                                {
                                    showData.dueDateBr == "30/11/-0001" ? null : 
                                    <>
                                        <tr>
                                            <td className="subtitle_1" align="left">Data de vencimento:</td>
                                        </tr>
                                        <tr>
                                            <td align="left">{ showData.dueDateBr }</td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table> 
                    </div>
                </div>
                
                {
                    Object.keys(showUpdate).length > 0 ?
                        <>
                            <div className="div_data_budget" style={ { borderBottom: "none", padding: "20px 40px 0px" } }>
                                <div className="">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td className="subtitle_1" align="left">Link Monday:</td>
                                            </tr>
                                            <tr>
                                                <td align="left">{ showUpdate.link_monday }</td>
                                            </tr>
                                            <tr>
                                                <td className="subtitle_1" align="left">Pasta do drive:</td>
                                            </tr>
                                            <tr>
                                                <td align="left">{ showUpdate.folder_drive }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td className="subtitle_1" align="left">Gestor responsável:</td>
                                            </tr>
                                            <tr>
                                                <td align="left">{ showUpdate.gest_resp }</td>
                                            </tr>
                                            <tr>
                                                <td className="subtitle_1" align="left">Direção e criação:</td>
                                            </tr>
                                            <tr>
                                                <td align="left">{ showUpdate.direction_creation }</td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>
                            </div> 
                            <div className="div_data_budget" style={ { borderTop: "none", padding: "0px 40px 20px" } }>
                                <div className="">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td className="subtitle_1" align="left">Observação:</td>
                                            </tr>
                                            <tr>
                                                <td align="left" dangerouslySetInnerHTML={ { __html: showUpdate.text ? showUpdate.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>
                            </div>
                        </>
                    : null
                }

                <div className="div_show_contents">
                    {
                        showData.services.map((key, index)=>{
                            return(
                                <div className="show_budget_desktop" key={ index }>
                                    <table className="" cellPadding="0" cellSpacing="0">
                                        {
                                            key.title == '' ? null :
                                            <thead>
                                                <tr style={ { backgroundColor: '#000000', color: "#ffffff" }}>
                                                    <td className="" colSpan="6">
                                                        { key.title }
                                                    </td>
                                                </tr>
                                            </thead>
                                        }
                                        <thead>
                                            <tr>
                                                <th width="20" align="center">N°</th>
                                                <th className="th_table">Item & Descrição</th>
                                            </tr>
                                        </thead>
                                        {
                                            key.list_services.map((key_1, index_1)=>{
                                                return(
                                                    <tbody key={ index_1 }>
                                                        {
                                                            key_1.service.map((key_2, index_2)=>{
                                                                return(
                                                                    <tr key={ index_2 }>
                                                                        <td className="" align="center">{ index_2 + 1 }</td>
                                                                        <td className="td_right">
                                                                            <div className="service_title">{ key_2.title }</div>
                                                                            <div className="service_text" dangerouslySetInnerHTML={ { __html: key_2.text ? key_2.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                )
                                            })
                                        }
                                    </table>
                                </div>
                            )
                        })
                    }

                    {/* 
                    Aguardar retorno se vai ou não mostrar essa opção
                    {
                        showData.show_group ? 
                        <div className="">
                            <table className="" cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr style={ { backgroundColor: '#000000', color: "#ffffff" }}>
                                        <td className="" colSpan="3">Resumo do orçamento</td>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th width="20" align="center">N°</th>
                                        <th>Título</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        showData.services.map((elem, index)=>{
                                            return(
                                                <tr key={ index }>
                                                    <td className="" align="center">{ index + 1 }</td>
                                                    <td className="" align="left">{ elem.title }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        : null
                    } 
                    */}
                </div>

                {
                    showData.text_fixed.length > 0 ? 
                    <div className="div_text_fixed">
                        {
                            showData.text_fixed.map((key, index)=>{
                                return(
                                    <div className="" key={ index }>
                                        <div className="subtitle_1">{ key.title }</div>
                                        <div className="" dangerouslySetInnerHTML={ { __html: key.text ? key.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
 
                <div className="div_signature">
                    <div className="show_signature">
                        <div className="div_img">
                            <img alt="img" src={ "./assets/signature/" + showData.signatureImg } className="img" />
                        </div>
                        <div className="">{ showData.nameSignature }</div>
                        <div className="subtitle_1">Assinatura Autorizada</div>
                    </div>
                </div>
            </div>
        </div>
    )
}