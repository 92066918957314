import { useState, useEffect } from "react";

import './Confirmation.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SvgDelete, SvgSave, SvgSetaRight } from "components/SvgFile";

import { Reg_Config } from "services/RegisterData";

export default function Page_Confirmation(props){

    const [ loading, setLoading ]     = useState(false);
    const [ emails, setEmails ] = useState(GetDataPage('config_email'));  
    
    const [ listEmail, setListEmail ] = useState(emails);  

    function HandleData(type, index, value){
        const newData = [...listEmail];
        newData[index][type] = value;
        setListEmail(newData);
    }

    function DeleteData(type, index, id, value){
        if(id == 0){
            console.log('Teste 11');
            const newData = [...listEmail];
            newData.splice(index, 1);
            setListEmail(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "config_email", "type": type, "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Config(listEmail, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterDataPage('config_email', setEmails);
    }, []);

    useEffect(()=>{
        setEmails(GetDataPage('config_email'));
        setListEmail(emails);
    }, [emails]);

    return(
        <div className="Page_Confirmation">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Configuração
                        </div>
                    </div>
                    <div className="list_opt_alt_page">
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Lista de e-mails</div>
                        <div className="new_data_page" onClick={ ()=>{ setListEmail([...listEmail, { "id": 0, "type": "updates", "email": "" }]); } }>
                            <span className="icons_menu">+</span> e-mails
                        </div>
                    </div>

                    {
                        listEmail.length > 0 ?
                            listEmail.map((elem, index)=>{
                                return(
                                    <div className="list_input_data list_inf_data" key={ index }>
                                        <div className="div_input">
                                            <select onChange={ (e)=>{ HandleData('type', index, e.target.value) } } value={ elem.type }>
                                                <option value="">#</option>
                                                <option value="updates">Updates de venda</option>
                                            </select>
                                            <span className="name_input">Type</span>
                                        </div>
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleData('email', index, e.target.value) } } value={ elem.email } maxLength="60" />
                                            <span className="name_input">E-mail</span>
                                        </div>
                                        <div className="div_input" onClick={ ()=>{ DeleteData('delete_email', index, elem.id, elem.email) } } style={ { display: "flex" } }>
                                            <SvgDelete color="#f00000" className="icons" />
                                        </div>
                                    </div>
                                )
                            })
                        :
                        <div className="list_input_data">
                            <div className="no_data">Nenhuma marca encontrada...</div>
                        </div>
                    }
                </div>
            </form>
        </div>
    )
}