import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalState } from 'interface/PopUp';
import { GetUserData, RegisterUserData } from 'interface/Users';
import { GetListPag, SetListPag, RegisterListPag } from 'interface/Page';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePage } from 'fixedData';

import { SvgClient, SvgClipBoard, SvgDashboard, SvgDashboardAbout, SvgDashboardBudget, SvgDashboardExit, SvgDashboardPresentation, SvgDashboardProject, SvgDashboardService, SvgLogoTipo, SvgLogoTipo_2, SvgMenuHamburguer, SvgMenuPoint, SvgSettings, SvgTasks, SvgUser, SvgUserDash } from 'components/SvgFile';

import PopUP_ShowFile from 'components/PopUp/ShowFile';
import PopUp_PhotoCrop from 'components/PopUp/PhotoCrop';
import PopUP_EditProfile from 'components/PopUp/EditProfile';
import PopUP_Confirmation from 'components/PopUp/Confirmation';
import PopUP_ReturnResponse from 'components/PopUp/ReturnResponse';

import Page_About from 'components/Page/About';

import Page_Clients from 'components/Page/Clients';
import Page_Clients_Details from 'components/Page/Clients/Details';

import Page_Portfolio from 'components/Page/Portfolio';
import Page_Portfolio_Tags from 'components/Page/Portfolio/Tags';
import Page_Portfolio_Details from 'components/Page/Portfolio/Details';

import Page_Report from 'components/Page/Report';
import Page_Report_Budget from 'components/Page/Report/Budget';
import Page_Report_Clients from 'components/Page/Report/Clients';
import Page_Report_Proposal from 'components/Page/Report/Proposal';
import Page_Report_Portfolio from 'components/Page/Report/Portfolio';

import Page_Proposal from 'components/Page/Proposal';
import Page_Proposal_Details from 'components/Page/Proposal/Details';

import Page_Users from 'components/Page/Users';
import Page_Users_Details from 'components/Page/Users/Details';

import Page_Services from 'components/Page/Services';
import Page_Services_Combo from 'components/Page/Services/Combo';
import Page_Service_Details from 'components/Page/Services/Details';
import Page_Services_Category from 'components/Page/Services/Category';
import Page_Services_Combo_Details from 'components/Page/Services/Combo/Details';

import Page_Budget from 'components/Page/Budget';
import Page_Budget_Details from 'components/Page/Budget/Details';
import Page_Budget_Signature from 'components/Page/Budget/Signature';
import Page_Budget_TextFixed from 'components/Page/Budget/TextFixed';
import PopUP_Historic from 'components/PopUp/Historic';
import PopUp_Color from 'components/PopUp/Color';
import Page_Confirmation from 'components/Page/Confirmation';
import Page_Tasks from 'components/Page/Tasks';
import PopUP_AltStatusTasks from 'components/PopUp/AltStatusTasks';

export default function Contents(){

    const cookies = new Cookies();
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));

    const [ status, setStatus ] = useState(false);

    const url      = new URLSearchParams(window.location.search);
    const idBudget = url.get("budget");

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('id', setUserId);
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);

        if(idBudget > 0){
            SetListPag('currentPage', 'budget_details');
            SetListPag('currentPageId', idBudget);
        }
    }, []);

    useEffect(()=>{
        if(idBudget > 0){
            SetListPag('currentPage', 'budget_details');
            SetListPag('currentPageId', idBudget);
        }
    }, [idBudget]);

    function EditProfile(){
        SetModalState('Profile', true);
    }

    function CkickPage(value){
        SetListPag('currentPage', value);
    }

    function CurrentPage(){
        window.history.pushState("", "", "/");
        switch(currentPage) {
            case "confirmation":
                return <Page_Confirmation CkickPage={ CkickPage } />

            case "about_chroma":
                return <Page_About CkickPage={ CkickPage } />;

            case "client":
                return <Page_Clients CkickPage={ CkickPage } />;
            case "client_details":
                return <Page_Clients_Details CkickPage={ CkickPage } />;


            case "access_dash":
                return <Page_Users CkickPage={ CkickPage } />;
            case "access_dash_details":
                return <Page_Users_Details CkickPage={ CkickPage } />;

            case "portfolio":
                return <Page_Portfolio CkickPage={ CkickPage } />;
            case "portfolio_details":
                return <Page_Portfolio_Details CkickPage={ CkickPage } />;
            case "show_tag":
                return <Page_Portfolio_Tags CkickPage={ CkickPage } />;

            case "proposal":
                return <Page_Proposal CkickPage={ CkickPage } />;
            case "proposal_details":
                return <Page_Proposal_Details CkickPage={ CkickPage } />;

            case "services":
                return <Page_Services CkickPage={ CkickPage } />;
            case "services_details":
                return <Page_Service_Details CkickPage={ CkickPage } />;
            case "services_combo":
                return <Page_Services_Combo CkickPage={ CkickPage } />;
            case "services_combo_details":
                return <Page_Services_Combo_Details CkickPage={ CkickPage } />;
            case "services_category":
                return <Page_Services_Category CkickPage={ CkickPage } />;

            case "budget":
                return <Page_Budget CkickPage={ CkickPage } />;
            case "budget_details":
                return <Page_Budget_Details CkickPage={ CkickPage } />;
            case "budget_signature":
                return <Page_Budget_Signature CkickPage={ CkickPage } />;
            case "budget_textfixed":
                return <Page_Budget_TextFixed CkickPage={ CkickPage } />;

            case "report":
                return <Page_Report CkickPage={ CkickPage } />;
            case "report_clients":
                return <Page_Report_Clients CkickPage={ CkickPage } />;
            case "report_portfolio":
                return <Page_Report_Portfolio CkickPage={ CkickPage } />;
            case "report_proposal":
                return <Page_Report_Proposal CkickPage={ CkickPage } />;
            case "report_budget":
                return <Page_Report_Budget CkickPage={ CkickPage } />;

            case "tasks":
                return <Page_Tasks CkickPage={ CkickPage } />

            default:
                return '';
                // return <QtdData CkickPage={ CkickPage } />;
        }
    }

    function Logout(){
        SetListPag('page', 'index');

        window.history.pushState("", "", "/");
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    return(
        <div className="Contents">
            <div className={ status == true ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">
                    <div className="show_data_top">

                        <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                            <div className={ status == true ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                                <div className="">
                                    {
                                        status == true ?
                                        <SvgLogoTipo_2 color="#ffffff" className="logotipo2" /> :
                                        <SvgLogoTipo color="#ffffff" className="logotipo" />
                                    }
                                </div>
                                {
                                    status == true ? null :
                                    <div className="">
                                        <SvgMenuHamburguer color="#ffffff" className="icons" />
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <div className="opt_menu_dash">
                            {
                                userAccess == 1 ?
                                <>
                                    <div className={ currentPage == "index" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("index"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboard color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Dashboard</div>
                                        </div>
                                    </div>
                                    <div className={ currentPage == "confirmation" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("confirmation"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgSettings color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Configuração</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "about_chroma" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("about_chroma"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardAbout color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Sobre a Chroma</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "client" || currentPage == "client_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("client"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgClient color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Clientes</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "portfolio" || currentPage == "portfolio_details" || currentPage == "show_tag" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("portfolio"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardProject color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Portfólio</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "proposal" || currentPage == "proposal_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("proposal"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardPresentation color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Propostas</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "services" || currentPage == "services_details" || currentPage == "services_combo" || currentPage == "services_combo_details" || currentPage == "services_category" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("services"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardService color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Serviços</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "budget" || currentPage == "budget_details" || currentPage == "budget_signature" || currentPage == "budget_textfixed" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("budget"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardBudget color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Orçamentos</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgUserDash color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Usuários</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "report" || currentPage == "report_clients" || currentPage == "report_portfolio" || currentPage == "report_proposal" || currentPage == "report_presentation" || currentPage == "report_budget" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("report"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgClipBoard color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Relatório</div>
                                        </div>
                                    </div>
                                </> 
                                :
                                <>
                                    {
                                        userPage.map((key, index)=>{
                                            let iconMenu   = '';
                                            switch (key.page) {
                                                case 'about_chroma':
                                                        iconMenu = <SvgDashboardAbout color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'client':
                                                        iconMenu = <SvgClient color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'portfolio':
                                                        iconMenu = <SvgDashboardProject color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'proposal':
                                                        iconMenu = <SvgDashboardPresentation color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'services':
                                                        iconMenu = <SvgDashboardService color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'budget':
                                                        iconMenu = <SvgDashboardBudget color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'report':
                                                        iconMenu = <SvgClipBoard color="#FFC400" className="icons_dash" />;
                                                    break;
                                            }
                                            
                                            let activeMenu = '';
                                            if(key.page == 'client' && currentPage == 'client_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'proposal' && currentPage == 'proposal_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'portfolio' && currentPage == 'portfolio_details' && currentPage == 'show_tag'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'services' && currentPage == 'services_details' && currentPage == 'show_category'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'budget' && currentPage == 'budget_details' && currentPage == 'budget_textfixed' && currentPage == 'budget_signature'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'report' && currentPage == 'report_budget' && currentPage == "report_clients" && currentPage == "report_project" && currentPage == "report_presentation"){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(currentPage == key.page){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else {
                                                status == true ? activeMenu = "show_menu_dash show_menu_dash_": activeMenu = "show_menu_dash";
                                            }
                                            
                                            return(
                                                <div className={ activeMenu } onClick={ ()=>{ CkickPage(key.page); } } key={ index }>
                                                    <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                        <div className="div_icons">
                                                            { iconMenu }
                                                        </div>
                                                        <div className={ status == true ? "menu_close" : "" }>
                                                            { key.name }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }

                            <div className={ currentPage == "tasks" || currentPage == "tasks_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("tasks"); } }>
                                <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgTasks color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status == true ? "menu_close" : "" }>Tarefas</div>
                                </div>
                            </div>

                            <div className={ status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status == true ? "menu_close" : "" }>Sair</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div_profile">
                        <div className={ status == true ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                            <div className="div_img">
                                {
                                    userFile !='' ?
                                    <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                    <SvgUser color="#FFFFFF" className="show_img_profile" />
                                }
                            </div>
                            <div className={ status == true ? "name_user menu_close" : "name_user" }>
                                { userName }
                            </div>
                            <div className={ status == true ? "open_profile menu_close" : "open_profile" }>
                                <SvgMenuPoint color="#ffffff" className="icons" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
            <PopUP_Historic />
            <PopUP_AltStatusTasks />
            <PopUP_ReturnResponse />
            <PopUP_EditProfile />
            <PopUp_PhotoCrop />
            <PopUP_ShowFile />
            <PopUP_Confirmation />
            <PopUp_Color />
        </div>
    )
}
