import { useState, useEffect } from "react";

import './Details.css';

import Select, { components } from 'react-select';

import JoditEditor from 'jodit-react';
import { config, } from "fixedData";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import TextareaAutosize from 'react-textarea-autosize';

import { SvgAddData, SvgCopy, SvgDelete, SvgEye, SvgEyeClose, SvgHistoric, SvgLink_opt2, SvgLockClose, SvgLockOpen, SvgPdf, SvgSave, SvgSetaDown, SvgSetaRight, SvgSetaUp, SvgSettings, SvgStatus, SvgText } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import Toast from "components/Toast";
import { Reg_AltPosition, Reg_Budget } from "services/RegisterData";
import PopUP_SaveBudget from "components/PopUp/SaveBudget";
import PopUP_HistoricBudget from "components/PopUp/HistoricBudget";
import PopUP_Tasks from "components/PopUp/Tasks";
import PopUP_SalesUpdates from "components/PopUp/SalesUpdates";
import PopUP_SendEmail from "components/PopUp/SendEmail";

export default function Page_Budget_Details(){

    const [ loading, setLoading ] = useState(false);
    const [ toast, setToast ]     = useState(false);

    const [ idUser, setIdUser ]               = useState(GetUserData("id"));

    const [ dataPage, setDataPage ]           = useState(GetDataPage('budget'));
    const [ listMotives, setListMotives ]     = useState(GetDataPage('budget_motive'));
    const [ showStatus, setShowStatus ]       = useState(GetDataPage('budget_status'));
    const [ showSignature, setShowSignature ] = useState(GetDataPage('budget_signature'));
    const [ showTextFixed, setShowTextFixed ] = useState(GetDataPage('budget_text_fixed'));
    const [ showHistoric, setShowHistoric ]   = useState(GetDataPage('budget_historic'));
    const [ showCategory, setShowCategory ]   = useState(GetDataPage('budget_category'));

    const [ idNew, setIdNew ]       = useState(GetListPag('idNewRegister'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ historic, setHistoric ] = useState(CheckedHistoric());

    const [ showClient, setShowClient ]       = useState(GetDataPage("client"));
    const [ showPortfolio, setShowPortfolio ] = useState(GetDataPage("portfolio"));
    const [ showProposal, setShowProposal ]   = useState(GetDataPage("proposal"));
    const [ showService, setShowService ]     = useState(GetDataPage("services"));
    const [ showCombo, setShowCombo ]         = useState(GetDataPage("services_combo"));
    
    const [ task, setTask ]         = useState(GetDataPage('list_tasks'));
    const [ showTask, setShowTask ] = useState(ShowQtdTask());

    const [ typeDiscount, setTypeDiscount ] = useState(InitialData('type_discount'));
    const [ discount, setDiscount ]         = useState(InitialData('discount'));

    const [ lock_, setLock_ ]             = useState(InitialData('lock_'));
    const [ url, setUrl ]                 = useState(InitialData('url'));
    const [ version, setVersion ]         = useState(InitialData('version'));
    const [ code, setCode ]               = useState(InitialData('code'));
    const [ signature, setSignature ]     = useState(InitialData('signature'));
    const [ typeBudget, setTypeBudget ]   = useState(InitialData('type_budget'));
    const [ chanceClose, setChanceClose ] = useState(InitialData('chance_close'));
    const [ proposal, setProposal ]       = useState(InitialData('proposal'));
    const [ client, setClient ]           = useState(InitialData('client'));
    const [ contact, setContact ]         = useState(InitialData('contact'));

    const [ nameClient, setNameClient ]       = useState('');
    const [ corporateName, setCorporateName ] = useState('');
    const [ cnpj, setCnpj ]                   = useState('');
    const [ actingArea, setActingArea ]       = useState('');
    const [ customerSince, setCustomerSince ] = useState('');
    const [ contactName, setContactName ]     = useState('');
    const [ contactEmail, setContactEmail ]   = useState('');
    const [ contactPhone, setContactPhone ]   = useState('');
    const [ contactOffice, setContactOffice ] = useState('');

    const [ listCategory, setListCategory ]   = useState(InitialData('list_category'));
    const [ subject, setSubject ]             = useState(InitialData('subject'));
    const [ year, setYear ]                   = useState(InitialData('year'));
    const [ status, setStatus ]               = useState(InitialData('status'));
    const [ dateStatus, setDateStatus ]       = useState(InitialData('date_status'));
    const [ dateStatusBr, setDateStatusBr ]   = useState(InitialData('dateStatusBr'));
    const [ newStatus, setNewStatus ]         = useState('');
    const [ newColor, setNewColor ]           = useState('');
    const [ billed, setBilled ]               = useState(InitialData('billed'));
    const [ dueDate, setDueDate ]             = useState(InitialData('due_date'));
    const [ dueDateBr, setDueDateBr ]         = useState(InitialData('dueDateBr'));
    const [ motive, setMotive ]               = useState(InitialData('motive'));
    const [ motiveName, setMotiveName ]       = useState(InitialData('motiveName'));
    const [ newMotive, setNewMotive ]         = useState('');
    const [ obs, setObs ]                     = useState(InitialData('obs'));
    const [ dateStart, setDateStart ]         = useState(InitialData('date_start'));
    const [ dateStartBr, setDateStartBr ]         = useState(InitialData('dateStartBr'));
    const [ dateEnd, setDateEnd ]             = useState(InitialData('date_end'));
    const [ dateEndBr, setDateEndBr ]             = useState(InitialData('dateEndBr'));
    const [ mountingStart, setMountingStart ] = useState(InitialData('mounting_start'));
    const [ mountingStartBr, setMountingStartBr ] = useState(InitialData('mountingStartBr'));
    const [ mountingEnd, setMountingEnd ]     = useState(InitialData('mounting_end'));
    const [ mountingEndBr, setMountingEndBr ]     = useState(InitialData('mountingEndBr'));
    const [ services, setServices ]           = useState(InitialData('services'));
    const [ textFixed, setTextFixed ]         = useState(InitialData('text_fixed'));

    const [ showIconText, setShowIconText ] = useState(true);
    const [ showGroup, setShowGroup ]       = useState(InitialData('show_group'));

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(newData){
                if(type == 'signature'){
                    if(newData['signature'] != 0){
                        return { "value": newData['signature'], "label": newData['nameSignature'] };
                    }
                    return { "value": "", "label": "..." };
                }
                if(type == 'type_budget'){
                    return { "value": newData['type'], "label": newData['nameType'] };
                }
                if(type == 'client'){
                    if(newData['type'] != 1){
                        return { "value": newData['id_given'], "label": newData['nameClient'] };
                    }
                    return { "value": "", "label": "..." };
                }
                if(type == 'contact'){
                    if(newData['id_given'] == client.value){
                        if(newData['contact'] != 0){
                            return { "value": newData['contact'], "label": newData['nameContact'] };
                        }else {
                            return { "value": "", "label": "..." };
                        }
                    }else {
                        return { "value": "", "label": "..." };
                    }
                }
                if(type == 'proposal'){
                    if(newData['type'] == 1){
                        let nameLabel = newData['nameClient'] + " - " + newData['nameProject'];
                        return { "value": newData['id_given'], "label": nameLabel };
                    }
                    return { "value": "", "label": "..." };
                }
                if(type == 'billed'){
                    if(newData[type] !=''){
                        return { "value": newData[type], "label": newData[type] }
                    }
                    return { "value": "Não faturado", "label": "Não faturado" };
                }
                if(type == 'status'){
                    return { "value": newData[type], "label": newData['nameStatus'] }
                }
                if(type == 'motiveName'){
                    if(newData['motiveName'] != ""){
                        return newData['motiveName'];
                    }
                    return "Selecione uma opção"
                }
                if(type == 'chance_close'){
                    if(newData[type] == 0){
                        return { "value": newData[type], "label": "Não" }
                    }else {
                        return { "value": newData[type], "label": "Sim" }
                    }
                }
                return newData[type];
            }
            return ReturnPage();
        }

        if(type == 'motiveName'){
            return "Selecione uma opção"
        }

        if(type == 'type_discount'){
            return 'not'
        }

        if(type == 'chance_close'){
            return { "value": 0, "label": "Não" };
        }
        if(type == 'status'){
            return { "value": "Contato inicial", "label": "Contato inicial" };
        }
        if(type == 'tax'){
            return '20';
        }
        if(type == 'text_fixed'){
            const newData = [];
            showTextFixed.map((key, index)=>{
                if(key.type == 'Automatic'){
                    newData.push({"id": 0, "title": key.title, "text": key.text, "status": false});
                }
            });
            return newData;
        }
        if(type == 'list_services' || type == 'collaborators' || type == 'services' || type == 'list_category'){
            return [];
        }
        if(type == 'show_group'){
            return false;
        }

        if(idNew){
            if(type == 'type_budget'){
                return { "value": 1, "label": "Com apresentação" };
            }
            if(type == 'proposal'){
                const newData =  showProposal.find(item => item.id == idNew);
                let labelOpt = newData.nameClient + ' - ' + newData.title;
                return { "value": newData.id, "label": labelOpt };
            }
        }else {
            if(type == 'type_budget'){
                return { "value": 0, "label": "Somente orçamento" };
            }
        }
        return '';
    }
    
    const Option = (props) => (
        <components.Option { ...props } className="type_lock_close">
            { props.data.icon == 1 ? <img src="./assets/lock.png" className="icons" /> : null }            
            { props.data.label }
        </components.Option>
    );

    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue { ...props } className="type_lock_close">
            { props.data.icon == 1 ? <img src="./assets/lock.png" className="icons" /> : null }    
            { children }
        </components.SingleValue>
    );

    function ShowQtdTask(){
        const dataType = task.filter(item => item.type == "budget");
        const newData  = [];
        dataType.map((elem, index)=>{
            if(elem.id_given == 0){
                newData.push(elem);
            }
            if(elem.id_given == idPage){
                newData.push(elem);
            }
        })
        return newData;
    }

    function CheckedHistoric(){
        if(idPage !=0){
            if(showHistoric.filter(item => item.id_budget == idPage)){
                const newData = showHistoric.filter(item => item.id_budget == idPage);
                return newData
            }
            return [];
        }
        return [];
    }

    function ShowProposal(){
        const newData = []
        showProposal.map((key, index)=>{
            newData.push({ "value": key.id, "label": "Cliente: " + key.nameClient + " - Proposta: " + key.title });
        })
        return newData;
    }

    function ShowOptCancel(){
        const newData = [{ "value": "newMotive", "label": "+ novo motivo" }];
        listMotives.map((elem, index)=>{
            newData.push({ "value": elem.id, "label": elem.title });
        })
        return newData;
    }

    function ShowSignature(){
        const newData = [];
        showSignature.map((key, index)=>{
            newData.push({ "value": key.id, "label": key.name });
        })
        return newData;
    }

    function ShowClient(){
        const newData = [{ "value": 0, "label": "#" }, { "value": "newClient", "label": "Novo cliente" }];
        showClient.map((key, index)=>{
            newData.push({ "value": key.id, "label": key.name });
        })
        return newData;
    }

    function ShowContact(){
        const newData = [{ "value": 0, "label": "#" }, { "value": "newContact", "label": "Novo contato" }];
        if(showClient.find(item => item.id == client.value)){
            showClient.find(item => item.id == client.value).contact.map((key, index)=>{
                if(key.phone !=''){
                    newData.push({ "value": key.id, "label": key.name + ' - ' + key.email + " - " + key.phone });
                }else {
                    newData.push({ "value": key.id, "label": key.name + ' - ' + key.email });
                }
            })
        }
        return newData;
    }

    function ShowStatus(){
        const newData = [{ "value": 0, "label": "#" }, { "value": "newStatus", "label": "Novo status" }];
        showStatus.map((key, index)=>{
            newData.push({ "value": key.id, "label": key.name });
        })
        return newData;
    }

    function ReturnPage(){
        SetListPag('currentPage', 'budget');
        SetListPag('currentPageId', 'remuve');
        window.history.pushState("", "", "/");
    }

    function CopyBudget(){
        SetModalData("Confirmation", { "id": idPage, "origin": "budget", "type": "copy_budget", "name": "#EST-" + code, "idUser": idUser });
        SetModalState('Confirmation', true);
    }

    function CopyLink(){
        setToast(true);
        navigator.clipboard.writeText(process.env.REACT_APP_API_URL + "?budget=" + url);
        setTimeout(() => {
            setToast(false);
        }, 1500);
    }

    function OpenColor(){
        SetModalData('Color', { "setColorPage": setNewColor });
        SetModalState('Color', true);
    }

    function LockBudget(){
        if(status.value == 8){
            setLoading(true);  
            Reg_Budget(idPage, idUser, typeDiscount, discount, lock_, version, signature.value, typeBudget.value, chanceClose.value, proposal.value, client.value, contact.value, nameClient, corporateName, cnpj, actingArea,  customerSince, contactName, contactEmail, contactPhone, contactOffice, subject, status.value, dateStatus, newStatus, newColor, billed.value, dueDate, motive, newMotive, obs, dateStart, dateEnd, mountingStart, mountingEnd, services, textFixed, showGroup, listCategory, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }else {
            SetModalData('SaveBudget', { "idBudget": idPage });
            SetModalState('SaveBudget', true)
        }
    }

    function HandleDataText(type, index, value){
        const newData = [...textFixed];
        if(type == 'type'){
            if(value == 'newText'){
                newData[index][type] = value;
            }else {
                const showNewData = showTextFixed.find(item => item.id == value);
                newData[index]['title'] = showNewData.title;
                newData[index]['text']  = showNewData.text;
            }
        }else {
            newData[index][type] = value;
        }
        setTextFixed(newData);
    }

    function DeleteText(id, index, value){
        if(id == 0){
            const newData = [...textFixed];
            newData.splice(index, 1);
            setTextFixed(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "budget", "type": "delete_budget_text_fixed", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function DeleteServices(id, index, value){
        if(id == 0){
            const newData = [...services];
            newData.splice(index, 1);
            setServices(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "budget", "type": "delete_services_all", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function ListPortfolios(){
        const options = [];
        showPortfolio.map((key, index)=>{
            let icons = 0;
            if(key.restricted == true){
                icons = 1;
            }
            let label = "Ano: " + key.year + " - Nome: " + key.project;
            options.push({
                "value": key.id, "label": label, "icon": icons
            });
        });
        return options;
    }

    function ShowServices(index, index_1, type){
        const options = [];
        if(type == 'service'){
            showService.map((key, index)=>{
                let label = key.code + " - " + key.name
                options.push({
                    "value": key.id, "label": label
                });
            });
        }else if(type == 'combo'){
            showCombo.map((key, index)=>{
                options.push({
                    "value": key.id, "label": key.name
                });
            });
        }
        return options;
    }

    function NewBlockService(){
        setServices([...services, { "id": 0, "title": "", "list_services": [] }]);
    }

    function AddNewServices(id, index){
        const newData = [...services];
        newData[index]['list_services'].push({ "id": 0, "id_edit": id, "type": 0, "service": [] });
        setServices(newData);
    }

    function HandleServices(type, index, value){
        const newData = [...services];
        newData[index][type] = value;
        setServices(newData);
    }

    function HandleServiceData(type, index, index_1, value){
        const newData = [...services];
        if(type == 'type'){
            newData[index]['list_services'][index_1]['service'] = [];
            newData[index]['list_services'][index_1][type] = value;

        }else if(type == 'service'){
            newData[index]['list_services'][index_1]['service'] = [];

            const dataService = showService.find(item => item.id == value);
            newData[index]['list_services'][index_1]['service'].push({ 
                "id": 0, 
                "id_service": dataService.id, 
                "code": dataService.code, 
                "tax": "", 
                "qtd": "",
                "title": dataService.name, 
                "text": dataService.textBudg, 
                "cash": dataService.cash,
                "service_code": dataService.code, 
                "list_link": []
            });

        }else if(type == 'combo'){
            newData[index]['list_services'][index_1]['service'] = [];

            const newService = [];
            const dataService = showCombo.find(item => item.id == value);
            dataService.services.map((key, index)=>{
                newService.push({ 
                    "id": 0, 
                    "id_service": key.idNew, 
                    "tax": "",
                    "qtd": "",
                    "code": key.code, 
                    "service_code": key.code, 
                    "title": key.name, 
                    "text": key.textBudg, 
                    "cash": key.cash,
                    "list_link": []
                });
            })   
            newData[index]['list_services'][index_1]['service'] = newService;  
        }
        setServices(newData);
    }

    function AddNewLink(index, index_1, index_2){
        const newData = [...services];
        newData[index]['list_services'][index_1]['service'][index_2]['list_link'].push({ "id": 0, "id_service": 0, "portfolio_id": 0, "type": "External", "link": "" });
        setServices(newData);
    }

    function HandleLink(index, index_1, index_2, index_3, type, value){
        const newData = [...services];
        newData[index]['list_services'][index_1]['service'][index_2]['list_link'][index_3][type] = value;
        setServices(newData);
    }

    function RemuveLink(id, index, index_1, index_2, index_3){
        if(id == 0){
            const newData = [...services];
            newData[index]['list_services'][index_1]['service'][index_2]['list_link'].splice(index_3, 1);
            setServices(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "budget", "type": "delete_services_link", "name": "Link" });
            SetModalState('Confirmation', true);
        }
    }

    function HandleServiceDataContents(index, index_1, index_2, type, value){
        const newData = [...services];
        newData[index]['list_services'][index_1]['service'][index_2][type] = value;
        setServices(newData);
    }

    function RemuveService(id, index, index_1){
        if(id == 0){
            const newData = [...services];
            newData[index]['list_services'].splice(index_1, 1);
            setServices(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "budget", "type": "delete_services_block", "name": "Serviço" });
            SetModalState('Confirmation', true);
        }
    }

    function RemuveServiceData(id, index, index_1, index_2){
        if(id == 0){
            const newData = [...services];
            newData[index]['list_services'][index_1]['service'].splice(index_2, 1);
            setServices(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "budget", "type": "delete_services_one", "name": "Serviço" });
            SetModalState('Confirmation', true);
        }
    }

    function EditLock(type){
        setLock_(type);
    }

    function LockOpen(){
        let version_historic = [];
        historic.map((elem, index)=>{
            version_historic.push(elem.version);
        })
        let version_bigger = Math.max(...version_historic);
        
        SetModalData('Confirmation', { "type": "lock_open_budget", "version_bigger": version_bigger, "version_actual": version, "id": idPage, "name": "#EST-" + code });
        SetModalState('Confirmation', true);
    }

    function OpenHistoric(){
        SetModalData('HistoricBudget', { "type": "historic_budget", "version": version,"id": idPage, "name": "#EST-" + code + " - Versão " + version });
        SetModalState('HistoricBudget', true);
    }

    function ClearText(){
        const newData = [...services];
        newData.map((key, index)=>{
            key.list_services.map((key_1, index_1)=>{
                key_1.service.map((key_2, index_2)=>{
                    key_2.text = '';
                })
            })
        });
        setServices(newData);
    }

    function DeleteAllText(){
        if(idPage == 0){
            setTextFixed([]);
        }else {
            SetModalData("Confirmation", { "id": idPage, "origin": "budget", "type": "delete_all_text", "name": "Deletar todos os textos" });
            SetModalState('Confirmation', true);
        }
    }

    function AltPosition(id, type, order_){
        Reg_AltPosition('budget', idPage, id, type, order_, ()=>{ CallbackSuccess(); }, ()=>{ CallbackError() });
    }

    function CloseText(){
        setShowIconText(!showIconText);
    }

    function ShowCashTotal(type, data){

        if(type == 'div'){
            let cashTotal = 0;            
            data.map((elem, index)=>{
                elem.service.map((elem_1, index_1)=>{
                    let countCash = 0;
                    if(elem_1.cash > 0){
                        countCash = elem_1.cash.replace('.', '');
                        countCash = countCash.replace(',', '.');
                        countCash = elem_1.qtd * countCash;
                    }

                    let tax  = elem_1.tax.replace('.', '');
                    tax      = tax.replace(',', '.');

                    let subtotal  = (countCash * tax) / 100;
                    let totalServ = subtotal + countCash;

                    cashTotal = totalServ + cashTotal;
                })
            });

            return (
                <span>
                    Valor total com imposto: 
                    <span className="show_cash_total">
                        { cashTotal.toLocaleString('pt-br', { minimumFractionDigits: 2 }) }
                    </span>
                </span>
            );
        }else {
            let cashTotal = 0;
            services.map((elem, index)=>{
                elem.list_services.map((elem_1, index_1)=>{
                    elem_1.service.map((elem_2, index_2)=>{
                        let countCash = 0;
                        if(elem_2.cash > 0){
                            countCash = elem_2.cash.replace('.', '');
                            countCash = countCash.replace(',', '.');
                            countCash = elem_2.qtd * countCash;
                        }

                        let tax  = elem_2.tax.replace('.', '');
                        tax      = tax.replace(',', '.');

                        let subtotal  = (countCash * tax) / 100;
                        let totalServ = subtotal + countCash;

                        cashTotal = totalServ + cashTotal;
                    })
                })
            })
            if(type == 'discount'){
                let altDiscount = '';
                if(discount){
                    altDiscount = discount.replaceAll('.', '');
                    altDiscount = discount.replaceAll(',', '.');
                }

                if(typeDiscount == 'percentage'){
                    let countDiscount     = cashTotal * (altDiscount / 100);

                    let cashTotalDiscount = cashTotal - countDiscount;
                    return "R$ " + cashTotalDiscount.toLocaleString('pt-br', { minimumFractionDigits: 2 });

                }else if(typeDiscount == 'cash'){
                    return "R$ " + (cashTotal - altDiscount).toLocaleString('pt-br', { minimumFractionDigits: 2 });
                }
            }else {
                return "R$ " + cashTotal.toLocaleString('pt-br', { minimumFractionDigits: 2 });
            }
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);  
        SetModalState('SaveBudget', false);
        if(signature.value){
            if(typeBudget.value != 1){
                if(client.value){
                    if(client.value == 'newClient'){
                        Reg_Budget(idPage, idUser, typeDiscount, discount, lock_, version, signature.value, typeBudget.value, chanceClose.value, proposal.value, client.value, contact.value, nameClient, corporateName, cnpj, actingArea, customerSince, contactName, contactEmail, contactPhone, contactOffice, subject, status.value, dateStatus, newStatus, newColor, billed.value, dueDate, motive, newMotive, obs, dateStart, dateEnd, mountingStart, mountingEnd, services, textFixed, showGroup, listCategory, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                    }else {
                        if(contact.value){
                            Reg_Budget(idPage, idUser, typeDiscount, discount, lock_, version, signature.value, typeBudget.value, chanceClose.value, proposal.value, client.value, contact.value, nameClient, corporateName, cnpj, actingArea, customerSince, contactName, contactEmail, contactPhone, contactOffice, subject, status.value, dateStatus, newStatus, newColor, billed.value, dueDate, motive, newMotive, obs, dateStart, dateEnd, mountingStart, mountingEnd, services, textFixed, showGroup, listCategory, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                        }else {
                            CallbackMissingData('É necessário selecionar um contato ou registrar um novo para registrar o orçamento!');
                        }
                    }
                }else {
                    CallbackMissingData('É necessário selecionar um cliente ou registrar um novo para registrar o orçamento!');
                }
            }else {
                if(proposal.value){
                    Reg_Budget(idPage, idUser, typeDiscount, discount, lock_, version, signature.value, typeBudget.value, chanceClose.value, proposal.value, client.value, contact.value, nameClient, corporateName, cnpj, actingArea, customerSince, contactName, contactEmail, contactPhone, contactOffice, subject, status.value, dateStatus, newStatus, newColor, billed.value, dueDate, motive, newMotive, obs, dateStart, dateEnd, mountingStart, mountingEnd, services, textFixed, showGroup, listCategory, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                }else {
                    CallbackMissingData('É necessário selecionar uma apresentação para registrar o orçamento!');
                }
            }
        }else {
            CallbackMissingData('É necessário selecionar uma assinatura para registrar o orçamento!');
        } 
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
        SetModalState('SaveBudget', false);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackMissingData(text){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "missingData", "text": text });
        SetModalState('ReturnResponse', true);
    }

    function OpenBudget(){
        window.open(process.env.REACT_APP_API_URL + '?project=budget/' + idPage, '_blank');
    }

    function InfStatusBudget(){
        SetModalData('ReturnResponse', { "page": "missingData", "text": "O orçamento está bloqueado para edição, para realizar qualquer alteração é necessário gerar uma nova revisão!" });
        SetModalState('ReturnResponse', true);
    }

    /* category */
    function HandleCategory(type, index, value){
        const newData = [...listCategory];
        newData[index][type] = value;
        setListCategory(newData);
    }
    function RemuveCategory(id, index, value){
        if(id == 0){
            const newData = [...listCategory];
            newData.splice(index, 1);
            setListCategory(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "budget", "type": "delete_categoria", "name": value });
            SetModalState('Confirmation', true);
        }
    }
    /* end */

    useEffect(()=>{
        RegisterUserData('id', setIdUser);

        RegisterDataPage('budget', setDataPage);
        RegisterDataPage('budget_motive', setListMotives);
        RegisterDataPage('budget_status', setShowStatus);
        RegisterDataPage('budget_signature', setShowSignature);
        RegisterDataPage("budget_text_fixed", setShowTextFixed);
        RegisterDataPage("budget_historic", setShowHistoric);
        RegisterDataPage("budget_category", setShowCategory);
        RegisterDataPage("list_tasks", setTask);

        RegisterListPag('idNewRegister', setIdNew);
        RegisterListPag('currentPageId', setIdPage);

        RegisterDataPage("client", setShowClient);
        RegisterDataPage('portfolio', setShowPortfolio);
        RegisterDataPage('proposal', setShowProposal);
        RegisterDataPage("services", setShowService);
        RegisterDataPage("services_combo", setShowCombo);
    }, []);

    useEffect(()=>{
        setIdUser(GetUserData("id"));

        setDataPage(GetDataPage('budget'));
        setListMotives(GetDataPage('budget_motive'));
        setShowStatus(GetDataPage('budget_status'));
        setShowSignature(GetDataPage('budget_signature'));
        setShowTextFixed(GetDataPage('budget_text_fixed'));
        setShowHistoric(GetDataPage('budget_historic'));
        setShowCategory(GetDataPage('budget_category'));
        setTask(GetDataPage('list_tasks'));
        setShowTask(ShowQtdTask());

        setIdPage(GetListPag('currentPageId'));
        setHistoric(CheckedHistoric());

        setListCategory(InitialData('list_category'));

        setShowClient(GetDataPage("client"));
        setShowPortfolio(GetDataPage("portfolio"))
        setShowProposal(GetDataPage("proposal"));
        setShowService(GetDataPage("services"));
        setShowCombo(GetDataPage("services_combo"));

        setTypeDiscount(InitialData('type_discount'));
        setDiscount(InitialData('discount'));

        setLock_(InitialData('lock_'));
        setUrl(InitialData('url'));
        setVersion(InitialData('version'));
        setCode(InitialData('code'));
        setSignature(InitialData('signature'));
        setTypeBudget(InitialData('type_budget'));
        setChanceClose(InitialData('chance_close'));
        setProposal(InitialData('proposal'));
        setClient(InitialData('client'));
        setContact(InitialData('contact'));

        setNameClient('');
        setCorporateName('');
        setCnpj('');
        setActingArea('');
        setCustomerSince('');
        setContactName('');
        setContactEmail('');
        setContactPhone('');
        setContactOffice('');
    
        setSubject(InitialData('subject'));
        setYear(InitialData('year'));
        setStatus(InitialData('status'));
        setDateStatus(InitialData('date_status'));
        setDateStatusBr(InitialData('dateStatusBr'));
        setNewStatus('');
        setNewColor('');
        setBilled(InitialData('billed'));
        setDueDate(InitialData('due_date'));
        setDueDateBr(InitialData('dueDateBr'));
        setMotive(InitialData('motive'));
        setMotiveName(InitialData('motiveName'));
        setNewMotive('');
        setObs(InitialData('obs'));
        setDateStart(InitialData('date_start'));
        setDateStartBr(InitialData('dateStartBr'));
        setDateEnd(InitialData('date_end'));
        setDateEndBr(InitialData('dateEndBr'));
        setMountingStart(InitialData('mounting_start'));
        setMountingStartBr(InitialData('mountingStartBr'));
        setMountingEnd(InitialData('mounting_end'));
        setMountingEndBr(InitialData('mountingEndBr'));
        setServices(InitialData('services'));
        setTextFixed(InitialData('text_fixed'));

        setShowGroup(InitialData('show_group'))
    }, [dataPage, idPage]);

    useEffect(()=>{
        setTask(GetDataPage('list_tasks'));
        setShowTask(ShowQtdTask());
    }, [task]);

    useEffect(()=>{
        ShowContact();
        setContact(InitialData('contact'));
    }, [client]);

    useEffect(()=>{
        setShowCategory(GetDataPage('budget_category'));
        setListCategory(InitialData('list_category'));
    }, [showCategory]);

    return(
        <div className="Page_Budget_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            {
                toast == false ? null : <Toast />
            }
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Orçamentos
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <div className="highlight_title_page">
                        {
                            idPage == 0 ? "Novo orçamento" : 
                                version == 0 ? "#EST-" + code : "#EST-" + code + " - Versão " + version
                        }
                    </div>
                    <div className="list_opt_alt_page">
                        {
                            idPage == 0 ? null :
                            <>
                                {
                                    historic.length == 0 ? null :
                                    <>
                                        <div className="div_icons" onClick={ ()=>{ OpenHistoric() } } data-tooltip-id="inf_Historic" data-tooltip-content="Histórico das revições" data-tooltip-place="top">
                                            <SvgHistoric color="#324d6b" className="icons" />
                                        </div>
                                        <Tooltip id="inf_Historic" />
                                    </>
                                }
                                {
                                    lock_ == 0 ?
                                    <>
                                        <div className="div_icons" data-tooltip-id="lock_open" data-tooltip-content="Orçamento liberado para edição" data-tooltip-place="top">
                                            <SvgLockOpen color="#324d6b" className="icons" />
                                        </div>
                                        <Tooltip id="lock_open" />
                                    </> :
                                    <>
                                        <div className="div_icons" onClick={ ()=>{ LockOpen() } } data-tooltip-id="lock_close" data-tooltip-content="Orçamento bloqueado para edição" data-tooltip-place="top">
                                            <SvgLockClose color="#324d6b" className="icons" />
                                        </div>
                                        <Tooltip id="lock_close" />
                                    </>
                                }
                                <div className="div_icons" onClick={ ()=>{ CopyLink() } } data-tooltip-id="link_opt" data-tooltip-content="Gerar link do orçamento" data-tooltip-place="top">
                                    <SvgLink_opt2 color="#324d6b" className="icons" />
                                </div>
                                <Tooltip id="link_opt" />

                                <div className="div_icons" onClick={ ()=>{ CopyBudget() } } data-tooltip-id="copy_budget" data-tooltip-content="Gerar uma cópia do orçamento" data-tooltip-place="top">
                                    <SvgCopy color="#324d6b" className="icons" />
                                </div>
                                <Tooltip id="copy_budget" />

                                <div className="div_icons" onClick={ ()=>{ OpenBudget(idPage) } } data-tooltip-id="show_pdf" data-tooltip-content="Abrir pdf" data-tooltip-place="top">
                                    <SvgPdf color="#F00000" className="icons" />
                                </div>
                                <Tooltip id="show_pdf" />
                            </>
                        }
                        {
                            lock_ == 1 ? null :
                            <div className="new_block_text" onClick={ ()=>{ LockBudget() } }>
                                <div className="">
                                    <SvgSave color="#ffffff" className="icons" />
                                </div>
                                <div className="">Salvar</div>
                            </div>
                        }
                    </div>
                </div>

                <div className="topic_fixed">
                    <div className="opt_click" onClick={ ()=>{ SetModalData('SalesUpdates', { "subject": subject, "year": year }); SetModalState('SalesUpdates', true) } }>Updates de venda</div>
                    <div className="opt_click" onClick={ ()=>{ SetModalData('Tasks', { "type": "budget" }); SetModalState('Tasks', true); } }>
                        Tarefas
                        {
                            showTask.length > 0 ? <div className="qtd_tasks">{ showTask.length }</div> : null
                        }
                    </div>
                </div>
                
                <div className="show_page_data"> 
                    <div className="list_type_data">
                        <div className="type_title">
                            Dados do orçamento
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input">
                            {
                                lock_ == 0 ?
                                <Select className="select_signature" defaultOptions options={ ShowSignature() } onChange={ (e)=> { setSignature(e) } } value={ signature } placeholder="..." />
                                :
                                <input type="text" className="select_signature not_edit" value={ signature.label } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                            }
                            <span className="name_input">Assinaturas</span>
                        </div>
                        <div className="div_input">
                            {
                                lock_ == 0 ?
                                <Select className="space_data" defaultOptions options={ [{ "value": 0, "label": "Somente orçamento" }, { "value": 1, "label": "Com proposta" }, { "value": 2, "label": "Lead" } ] } value={ typeBudget } onChange={ (e)=> { setTypeBudget(e); } } placeholder="..." />
                                :
                                <input type="text" className="type_budget not_edit" value={ typeBudget.label } onClick={ ()=>{ InfStatusBudget() } }  readOnly />
                            }                            
                            <span className="name_input">Tipo de orçamento</span>
                        </div>
                        <div className="div_input">
                            {
                                lock_ == 0 ?
                                <Select className="select_signature" defaultOptions options={ [{ "value": 0, "label": "Não" }, { "value": 1, "label": "Sim" } ] } value={ chanceClose } onChange={ (e)=> { setChanceClose(e); } } placeholder="..." />
                                :
                                <input type="text" className="select_signature chance_close not_edit" value={ chanceClose.label } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                            }
                            <span className="name_input">Chance de fechar</span>
                        </div>

                        {
                            typeBudget.value == 1 ?
                            <div className="div_input space_div">
                                {
                                    lock_ == 0 ?
                                    <Select className="space_div" defaultOptions options={ ShowProposal() } value={ proposal } onChange={ (e)=> { setProposal(e); } } placeholder="..." />
                                    :
                                    <input type="text" className="not_edit" value={ proposal.label } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                } 
                                <span className="name_input">Propostas</span>
                            </div> : 
                            <>
                                <div className="div_input">
                                    {
                                        lock_ == 0 ?
                                        <Select className="select_client" defaultOptions options={ ShowClient() } value={ client } onChange={ (e)=> { setClient(e) } } placeholder="..." />
                                        :
                                        <input type="text" className="select_client not_edit" value={ client.label } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                    }
                                    <span className="name_input">Cliente</span>
                                </div>
                                {
                                    client.value != 'newClient' ?
                                    <div className="div_input">
                                        {
                                            lock_ == 0 ?
                                            <Select className="space_contact" defaultOptions options={ ShowContact() } onChange={ (e)=> { setContact(e); } } value={ contact } placeholder="..." />
                                            :
                                            <input type="text" className="space_contact not_edit" value={ contact.label } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                        }                                        
                                        <span className="name_input">Contatos</span>
                                    </div> : null
                                }
                            </>
                        }
                    </div>
                    {
                        client.value != 'newClient' ? null :
                        <div className="list_input_data">                            
                            <div className="div_input space_div show_data_text">
                                <div className="show_title">Dados da nova empresa</div>
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setNameClient(e.target.value) } } value={ nameClient } maxLength="140" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setCorporateName(e.target.value) } } value={ corporateName } maxLength="100" />
                                        <span className="name_input">Razão Social</span>
                                    </div>
                                </div>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <input type="text" className="cnpj" onChange={ (e)=>{ setCnpj(e.target.value) } } value={ cnpj } maxLength="18" />
                                        <span className="name_input">CNPJ</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setActingArea(e.target.value) } } value={ actingArea } maxLength="100" />
                                        <span className="name_input">Área de atuação</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="customer_since" onChange={ (e)=>{ setCustomerSince(e.target.value) } } value={ customerSince } maxLength="4" />
                                        <span className="name_input">Cliente desde</span>
                                    </div>
                                </div>
                                <div className="show_title" style={ { marginTop: '16px' } }>Dados do contato</div>
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ setContactName(e.target.value) } } value={ contactName } maxLength="80" />
                                        <span className="name_input">Nome do contato</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" onChange={ (e)=>{ setContactEmail(e.target.value) } } value={ contactEmail } maxLength="40" />
                                        <span className="name_input">E-mail</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="phone" onChange={ (e)=>{ setContactPhone(e.target.value) } } value={ contactPhone } maxLength="20" />
                                        <span className="name_input">Telefone</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ setContactOffice(e.target.value) } } value={ contactOffice } maxLength="50" />
                                        <span className="name_input">Cargo</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        contact.value != 'newContact' ? null :
                        <div className="list_input_data">                            
                            <div className="div_input space_div show_data_text">
                                <div className="show_title">Dados do novo contato</div>
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ setContactName(e.target.value) } } value={ contactName } maxLength="80" />
                                        <span className="name_input">Nome do contato</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" onChange={ (e)=>{ setContactEmail(e.target.value) } } value={ contactEmail } maxLength="40" />
                                        <span className="name_input">E-mail</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="phone" onChange={ (e)=>{ setContactPhone(e.target.value) } } value={ contactPhone } maxLength="20" />
                                        <span className="name_input">Telefone</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ setContactOffice(e.target.value) } } value={ contactOffice } maxLength="50" />
                                        <span className="name_input">Cargo</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="list_input_data">   
                        <div className="div_input space_div">
                            {
                                lock_ == 0 ?
                                <input type="text" className="" onChange={ (e)=>{ setSubject(e.target.value) } } value={ subject } maxLength="140" required />
                                :
                                <input type="text" className="not_edit" value={ subject } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                            }
                            <span className="name_input">Assunto</span>
                        </div>
                        <div className="div_input">
                            {
                                lock_ == 0 ?
                                <Select className="select_status" defaultOptions options={ ShowStatus() } onChange={ (e)=> { setStatus(e) } } value={ { "value": status.value, "label": status.label } } placeholder="..." />
                                :
                                <input type="text" className="not_edit" value={ status.label } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                            }
                            <span className="name_input">Status</span>
                        </div>
                        {
                            status.label != 'Aprovado' ? 
                                status.value == 'newStatus' ? 
                                <>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setNewStatus(e.target.value) } } value={ newStatus } maxLength="140" required />
                                        <span className="name_input">Novo status</span>
                                    </div>
                                    <div className="div_input" onClick={ ()=>{ OpenColor() } }>
                                        <div className="div_color" style={ { backgroundColor: newColor } } />
                                        <span className="name_input">Cor do status</span>
                                    </div>
                                </> : null
                            :
                            <div className="div_input">
                                {
                                    lock_ == 0 ?
                                    <Select className="select_signature" defaultOptions options={[{ "value": "Faturado", "label": "Faturado" }, { "value": "Não faturado", "label": "Não faturado" }]} onChange={ (e)=> { setBilled(e) } } value={ billed } placeholder="..." />
                                    :
                                    <input type="text" className="select_signature not_edit" value={ billed.label } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                }
                                <span className="name_input">Faturamento</span>
                            </div>
                        }
                        {
                            status.label == 'Aprovado' || status.label == 'Cancelado' || status.label == 'Perdido' ? 
                            <div className="div_input">
                                {
                                    lock_ == 0 ?
                                    <input type="date" className="date_space" onChange={ (e)=>{ setDateStatus(e.target.value) } } value={ dateStatus } />
                                    :
                                    <input type="text" className="date_space not_edit" value={ dateStatusBr } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                }
                                <span className="name_input">Data status</span>
                            </div> : null
                        }
                        <div className="div_input">
                            {
                                lock_ == 0 ?
                                <input type="date" className="date_space" onChange={ (e)=>{ setDueDate(e.target.value) } } value={ dueDate } />
                                :
                                <input type="text" className="date_space not_edit" value={ dueDateBr } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                            }
                            <span className="name_input">Data Vencimento</span>
                        </div>
                    </div>
                    {
                        status.label === 'Cancelado' || status.label === 'Perdido' ? 
                        <div className="list_input_data">
                            <div className="space_div show_data_text">
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        {
                                            lock_ == 0 ?
                                            <Select className="space_div" defaultOptions options={ ShowOptCancel() } onChange={ (e)=> { setMotive(e.value); setMotiveName(e.label) } } value={ { "value": motive, "label": motiveName } } placeholder="..." />
                                            :
                                            <input type="text" className="not_edit" value={ motiveName } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                        }
                                        <span className="name_input">Motivo abreviado</span>
                                    </div>
                                    {
                                        motive != 'newMotive' ? null :
                                        <div className="div_input space_div">
                                            <input type="text" className="" onChange={ (e)=>{ setNewMotive(e.target.value) } } value={ newMotive } maxLength="60" required />
                                            <span className="name_input">Nome do novo motivo abreviado</span>
                                        </div>
                                    }
                                </div>
                                <div className="div_input">
                                    <div className="show_title">Observação sobre o cancelamento</div>
                                    {
                                        lock_ == 0 ?
                                        <JoditEditor className="height_textarea" config={ config } value={ obs !='' ? obs.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setObs(newContent) } />
                                        :      
                                        <TextareaAutosize className="not_edit" value={ obs !='' ? obs.replaceAll('&#34;', '"') : "" } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                    }
                                </div>
                            </div>
                        </div> : null
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            Dados do evento
                        </div>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input">
                            {
                                lock_ == 0 ?
                                <input type="date" className="date_space" onChange={ (e)=>{ setDateStart(e.target.value) } } value={ dateStart } />
                                :
                                <input type="text" className="date_space not_edit" value={ dateStartBr == "30/11/-0001" ? "Não definido" : dateStartBr } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                            }                            
                            <span className="name_input">Evento início</span>
                        </div>
                        <div className="div_input">
                            {
                                lock_ == 0 ?
                                <input type="date" className="date_space" onChange={ (e)=>{ setDateEnd(e.target.value) } } value={ dateEnd } />
                                :
                                <input type="text" className="date_space not_edit" value={ dateEndBr == "30/11/-0001" ? "Não definido" : dateEndBr } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                            }
                            <span className="name_input">Evento término</span>
                        </div>  
                        <div className="div_input">
                            {
                                lock_ == 0 ?
                                <input type="date" className="date_space" onChange={ (e)=>{ setMountingStart(e.target.value) } } value={ mountingStart } />
                                :
                                <input type="text" className="date_space not_edit" value={ mountingStartBr == "30/11/-0001" ? "Não definido" : mountingStartBr } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                            }                            
                            <span className="name_input">Montagem início</span>
                        </div>
                        <div className="div_input">
                            {
                                lock_ == 0 ?
                                <input type="date" className="date_space" onChange={ (e)=>{ setMountingEnd(e.target.value) } } value={ mountingEnd } />
                                :
                                <input type="text" className="date_space not_edit" value={ mountingEndBr == "30/11/-0001" ? "Não definido" : mountingEndBr } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                            }    
                            <span className="name_input">Montagem término</span>
                        </div>
                    </div>
                </div>
                
                <div className="show_page_data"> 
                    <div className="list_type_data">
                        <div className="type_title">
                            Lista das categorias
                        </div>
                        {
                            lock_ == 0 ?
                            <div className="new_data_page" onClick={ ()=>{ setListCategory([...listCategory, { "id": 0, "id_category": 0, "new_category": "" }]); } }>
                                <span className="icons_menu">+</span> categoria
                            </div>
                            : null
                        }
                    </div>
                    {
                        listCategory.length > 0 ?
                        <div className="div_show_category">
                            {
                                listCategory.map((elem, index)=>{
                                    return(
                                        <div className="list_input_data" key={ index }>
                                            <div className="div_input"> 
                                                {
                                                    lock_ == 0 ?
                                                    <select onChange={ (e)=>{ HandleCategory('id_category', index, e.target.value) } } value={ elem.id_category }>
                                                        <option value="">#</option>
                                                        <option value="addCategory">Nova categoria</option>
                                                        {
                                                            showCategory.map((elem_1, index_1)=>{
                                                                return(
                                                                    <option value={ elem_1.id } key={ index_1 }>{ elem_1.name }</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    :
                                                    <input type="text" className="not_edit" value={ elem.name_category } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                                } 
                                                <span className="name_input">Categoria</span>
                                            </div>
                                            {
                                                elem.id_category == "addCategory" ?
                                                <div className="div_input space_div">    
                                                    <input type="text" className="" onChange={ (e)=>{ HandleCategory('new_category', index, e.target.value) } } value={ elem.new_category } />
                                                    <span className="name_input">Nova categoria</span>
                                                </div>
                                                : null
                                            }
                                            {
                                                lock_ == 1 ? null :
                                                <>
                                                    <div className="div_input" onClick={ (e)=>{ RemuveCategory(elem.id, index, elem.name_category) } } style={ { display: 'flex' } } data-tooltip-id={ "seta_delete_category_" + index } data-tooltip-content="Deletar categoria" data-tooltip-place="top">
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                    <Tooltip id={ "seta_delete_category_" + index } />
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        <div className="no_date">Nenhuma categoria adicionada...</div>
                    }
                </div>

                {
                    idPage == 0 ? null :
                    <div className="show_page_data">
                        <div className="list_type_data">
                            <div className="type_title">
                                Resumo do orçamento
                            </div>
                            <div className="type_title clear_text">
                                <div className="">
                                    Total de grupos
                                </div>
                                <label className="switch">
                                    <div className="switch_wrapper">                                        
                                        {
                                            lock_ == 0 ?
                                            <input type="checkbox" value="true" onChange={ (e)=>{ setShowGroup(!showGroup) } } checked={ showGroup } /> :
                                            <input type="checkbox" value="true" checked={ showGroup } readOnly />
                                        }
                                        <span className="switch_button"></span>
                                    </div>
                                </label>   
                            </div>
                        </div>
                        <div className="list_input_data">
                            <div className="div_input">
                                {
                                    lock_ == 0 ?
                                    <select className="type_discount" onChange={ (e)=>{ setTypeDiscount(e.target.value) } } value={ typeDiscount }>
                                        <option value="not">Não</option>
                                        <option value="percentage">Porcentagem</option>
                                        <option value="cash">Valor</option>
                                    </select>
                                    :
                                    <input type="text" className="type_discount not_edit" value={ 
                                        typeDiscount == "not" ? "Não"
                                            : typeDiscount == "percentage" ? 
                                            "Porcentagem" : "Valor"

                                    } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                }  
                                <span className="name_input">Conceder desconto</span>
                            </div>
                            {
                                typeDiscount != 'not' ?  
                                <div className="div_input">
                                    <input type="text" className="discount" onChange={ (e)=>{ setDiscount(e.target.value) } } value={ discount.replace(/[^0-9\.\,\-]/g, '') } />
                                    <span className="name_input">{ typeDiscount == "percentage" ? "Porcentagem" : "Valor" }</span>
                                </div> : null
                            }
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="20" align="center">N°</th>
                                        <th>Título</th>
                                        <th width="140">Valor com imposto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        services.map((elem, index)=>{
                                            let cashUnitary = 0.00; 
                                            elem.list_services.map((elem_1, index_1)=>{
                                                elem_1.service.map((elem_2, index_2)=>{
                                                    let countCash = 0;
                                                    if(elem_2.cash > 0){
                                                        countCash = elem_2.cash.replace('.', '');
                                                        countCash = countCash.replace(',', '.');
                                                        countCash = elem_2.qtd * countCash;
                                                    }

                                                    let tax  = elem_2.tax.replace('.', '');
                                                    tax      = tax.replace(',', '.');

                                                    let subtotal  = (countCash * tax) / 100;
                                                    let totalServ = subtotal + countCash;

                                                    cashUnitary = totalServ + cashUnitary;
                                                })
                                            })

                                            return(
                                                <tr key={ index }>
                                                    <td className="" align="center">{ index + 1 }</td>
                                                    <td className="" align="left">{ elem.title }</td>
                                                    <td className="td_right" align="center">
                                                        {
                                                            cashUnitary.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td className="subtitle_1" colSpan={ 2 } align="right"><b>Total</b></td>
                                        <td className="td_right" align="center">
                                            {
                                                ShowCashTotal('', '')
                                            } 
                                        </td>
                                    </tr>
                                    {
                                        typeDiscount != 'not' ? 
                                        <>
                                            <tr>
                                                <td className="subtitle_1" colSpan={ 2 } align="right"><b>Desconto</b></td>
                                                <td className="td_right" align="center">
                                                    {
                                                        typeDiscount == "percentage" ? discount.replace(/[^0-9\.\,\-]/g, '') + "%" : "R$ " + discount.replace(/[^0-9\.\,\-]/g, '') 
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="subtitle_1" colSpan={ 2 } align="right"><b>Total com desconto</b></td>
                                                <td className="td_right" align="center">
                                                    {
                                                        ShowCashTotal('discount', '')
                                                    } 
                                                </td>
                                            </tr>
                                        </>
                                        : null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            <SvgSettings color="#2D3C53" className="icons" />
                            Serviços
                        </div>
                        {
                            lock_ == 1 ? null :
                            <div className="type_title clear_text">
                                <div className="" onClick={ ()=>{ CloseText() } } data-tooltip-id="type_eye" data-tooltip-content={ showIconText ? "Mostar os textos" : "Ocultar os textos" } data-tooltip-place="top">
                                    {
                                        showIconText == true ?
                                        <SvgEye color="#2D3C53" className="icons" /> :
                                        <SvgEyeClose color="#2D3C53" className="icons" />
                                    }
                                </div>
                                <Tooltip id="type_eye" />
                                <div className="">|</div>
                                <div className="" onClick={ ()=>{ ClearText() } }>
                                    Limpar texto
                                </div>   
                            </div>
                        }
                    </div>
                    {
                        services.map((key, index)=>{
                            return(
                                <div className="list_input_data" key={ index }>  
                                    <div className="div_input space_div show_data_text">
                                        <div className="list_type_service">
                                            <div className="list_input_data" style={ { marginBottom: '20px' } }>  
                                                <div className="div_input space_div">
                                                    {
                                                        lock_ == 0 ?
                                                        <input type="text" onChange={ (e)=>{ HandleServices('title', index, e.target.value) } } value={ key.title } maxLength="80" />
                                                        :
                                                        <input type="text" className="not_edit" value={ key.title } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                                    } 
                                                    <span className="name_input">Título</span>
                                                </div>
                                                {
                                                    lock_ == 1 ? null :
                                                    <>
                                                        {
                                                            index == 0 ? null :
                                                            <div className="div_input">
                                                                <div className="" onClick={ ()=>{ AltPosition(key.id, 'up_service', key.order_) } } data-tooltip-id={ "seta_up_" + index } data-tooltip-content="Alterar posição do bloco - Subir" data-tooltip-place="top">
                                                                    <SvgSetaUp className="icons" color="#6c757d" />
                                                                </div>
                                                                <Tooltip id={ "seta_up_" + index } />
                                                            </div>
                                                        }
                                                        {
                                                            (index + 1) == services.length ? null :
                                                            <div className="div_input">
                                                                <div className="" onClick={ ()=>{ AltPosition(key.id, 'down_service', key.order_) } } data-tooltip-id={ "seta_down_" + index } data-tooltip-content="Alterar posição do bloco - Descer" data-tooltip-place="bottom">
                                                                    <SvgSetaDown className="icons" color="#6c757d" />
                                                                </div>
                                                                <Tooltip id={ "seta_down_" + index } />
                                                            </div>
                                                        }
                                                        <div className="div_input">
                                                            <div className="new_data_page" onClick={ ()=>{ AddNewServices(key.id, index) } }>
                                                                <span className="icons_menu">+</span> serviço
                                                            </div>
                                                        </div>
                                                        <div className="div_input" onClick={ ()=>{ DeleteServices(key.id, index, key.title) } } style={ { display: "flex" } } data-tooltip-id={ "delete_" + index } data-tooltip-content="Deletar bloco" data-tooltip-place="top">
                                                            <SvgDelete color="#f00000" className="icons" />
                                                        </div>
                                                        <Tooltip id={ "delete_" + index } />
                                                    </>
                                                }
                                                <div className="div_input">
                                                    { ShowCashTotal('div', key.list_services) }
                                                </div>
                                            </div>
                                            {
                                                key.list_services.map((key_1, index_1)=>{
                                                    return(
                                                        <div className="list_inf_data" key={ index_1 }>
                                                            {
                                                                key_1.id !=0 ? null :
                                                                <div className="list_input_data">
                                                                    <div className="div_input"> 
                                                                        <select onChange={ (e)=>{ HandleServiceData('type', index, index_1, e.target.value) } } value={ key_1.type }>
                                                                            <option value="0">Serviços</option>
                                                                            <option value="1">Combo</option>
                                                                        </select>
                                                                        <span className="name_input">Tipo</span>
                                                                    </div>
                                                                    {
                                                                        key_1.type == 0 ?
                                                                        <div className="div_input space_div">
                                                                            <Select className="space_div" defaultOptions options={ ShowServices(index, index_1, 'service') } onChange={ (e)=> { HandleServiceData('service', index, index_1, e.value) } } value={ key_1.data } placeholder="..." />
                                                                            <span className="name_input">Lista dos serviços</span>
                                                                        </div> 
                                                                        :
                                                                        <div className="div_input space_div">
                                                                            <Select className="space_div" defaultOptions options={ ShowServices(index, index_1, 'combo') } onChange={ (e)=> { HandleServiceData('combo', index, index_1, e.value) } } value={ key_1.data } placeholder="..." />
                                                                            <span className="name_input">Lista dos combos</span>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        lock_ == 1 ? null :
                                                                        <>
                                                                            <div className="div_input" onClick={ ()=>{ RemuveService(key_1.id, index, index_1) } } style={ { display: 'flex' } } data-tooltip-id={ "delete_service_" + index_1 } data-tooltip-content="Deletar serviço" data-tooltip-place="top">
                                                                                <SvgDelete color="#f00000" className="icons" />
                                                                            </div>
                                                                            <Tooltip id={ "delete_service_" + index_1 } />
                                                                        </>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                key_1.service.map((key_2, index_2)=>{
                                                                    return(
                                                                        <div className="data_services" key={ index_2 }>
                                                                            <div className="list_input_data">
                                                                                <div className="div_input">
                                                                                    <input type="text" className="not_edit service_code" value={ key_2.service_code } readOnly />
                                                                                    <span className="name_input">Código do serviço</span>
                                                                                </div>

                                                                                <div className="div_input space_div">
                                                                                    {
                                                                                        lock_ == 0 ?
                                                                                        <input type="text" className="" onChange={ (e)=>{ HandleServiceDataContents(index, index_1, index_2, 'title', e.target.value) } } value={ key_2.title } maxLength="140" required />
                                                                                        :
                                                                                        <input type="text" className="not_edit" value={ key_2.title } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                                                                    }
                                                                                    <span className="name_input">Título</span>
                                                                                </div>
                                                                                <div className="div_input">
                                                                                    {
                                                                                        lock_ == 0 ?
                                                                                        <input type="text" className="tax" onChange={ (e)=>{ HandleServiceDataContents(index, index_1, index_2, 'qtd', e.target.value) } } value={ key_2.qtd } maxLength="20" required />
                                                                                        :
                                                                                        <input type="text" className="tax not_edit" value={ key_2.qtd } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                                                                    }
                                                                                    <span className="name_input">Qtd</span>
                                                                                </div>
                                                                                <div className="div_input">
                                                                                    {
                                                                                        lock_ == 0 ?
                                                                                        <input type="text" className="cash" onChange={ (e)=>{ HandleServiceDataContents(index, index_1, index_2, 'cash', e.target.value) } } value={ key_2.cash } maxLength="20" required />
                                                                                        :
                                                                                        <input type="text" className="cash not_edit" value={ key_2.cash } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                                                                    }
                                                                                    <span className="name_input">Valor uni.</span>
                                                                                </div>
                                                                                <div className="div_input">
                                                                                    {
                                                                                        lock_ == 0 ?
                                                                                        <input type="text" className="cash" onChange={ (e)=>{ HandleServiceDataContents(index, index_1, index_2, 'tax', e.target.value) } } value={ key_2.tax } maxLength="20" required />
                                                                                        :
                                                                                        <input type="text" className="cash not_edit" value={ key_2.tax } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                                                                    }
                                                                                    <span className="name_input">Imposto</span>
                                                                                </div>
                                                                                {
                                                                                    lock_ == 1 ? null :
                                                                                    <>
                                                                                        {
                                                                                            index_2 == 0 ? null :
                                                                                            <div className="div_input">
                                                                                                <div className="" onClick={ ()=>{ AltPosition(key_2.id, 'up_list_service', key_2.order_) } } data-tooltip-id={ "seta_up_serv_" + index_2 } data-tooltip-content="Alterar posição do bloco - Subir" data-tooltip-place="top">
                                                                                                    <SvgSetaUp className="icons" color="#6c757d" />
                                                                                                </div>
                                                                                                <Tooltip id={ "seta_up_serv_" + index_2 } />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            (index_2 + 1) == key_1.service.length ? null :
                                                                                            <div className="div_input">
                                                                                                <div className="" onClick={ ()=>{ AltPosition(key_2.id, 'down_list_service', key_2.order_) } } data-tooltip-id={ "seta_down_serv_" + index_2 } data-tooltip-content="Alterar posição do bloco - Descer" data-tooltip-place="top">
                                                                                                    <SvgSetaDown className="icons" color="#6c757d" />
                                                                                                </div>
                                                                                                <Tooltip id={ "seta_down_serv_" + index_2 } />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            key_1.service.length > 1 ? 
                                                                                            <>
                                                                                                <div className="div_input" onClick={ (e)=>{ RemuveServiceData(key_2.id, index, index_1, index_2) } } style={ { display: 'flex' } } data-tooltip-id={ "seta_delete_serv_" + index_2 } data-tooltip-content="Deletar serviço" data-tooltip-place="top">
                                                                                                    <SvgDelete color="#f00000" className="icons" />
                                                                                                </div>
                                                                                                <Tooltip id={ "seta_delete_serv_" + index_2 } />
                                                                                            </>
                                                                                            : null
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            <div className={ showIconText == true ? "list_input_data" : "list_input_data close_list_text" }>
                                                                                <div className="div_input space_div">
                                                                                    {
                                                                                        lock_ == 0 ?
                                                                                        <JoditEditor config={ config } value={ key_2.text ? key_2.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleServiceDataContents(index, index_1, index_2, 'text', newContent) } />
                                                                                        :
                                                                                        <div className="text_ not_edit" onClick={ ()=>{ InfStatusBudget() } }dangerouslySetInnerHTML={ { __html: key_2.text ? key_2.text.replaceAll('&#34;', '"') : "" } } />    
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            <div className="list_type_data">
                                                                                <div className="type_title">Link</div>
                                                                                {
                                                                                    lock_ == 1 ? null :
                                                                                    <div className="new_data_page" onClick={ ()=>{ AddNewLink(index, index_1, index_2) } }>
                                                                                        <span className="icons_menu">+</span> link
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                            {
                                                                                key_2.list_link.map((key_3, index_3)=>{
                                                                                    return(
                                                                                        <div className="list_input_data" key={ index_3 }>
                                                                                            <div className="div_input">
                                                                                                {
                                                                                                    lock_ == 0 ?
                                                                                                    <select onChange={ (e)=>{ HandleLink(index, index_1, index_2, index_3, 'type', e.target.value) } } value={ key_3.type }>
                                                                                                        <option value="Internal">Interno</option>
                                                                                                        <option value="External">Externo</option>
                                                                                                    </select>
                                                                                                    :
                                                                                                    <input type="text" className="not_edit" value={ key_3.type } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                                                                                }
                                                                                                <span className="name_input">Tipo</span>
                                                                                            </div>
                                                                                            <div className="div_input space_div">
                                                                                                {
                                                                                                    key_3.type == 'External' ?
                                                                                                    <> 
                                                                                                        {
                                                                                                            lock_ == 0 ?                                                                                                            
                                                                                                            <input type="text" className="" onChange={ (e)=>{ HandleLink(index, index_1, index_2, index_3, 'link', e.target.value); } } value={ key_3.link } required />
                                                                                                            :
                                                                                                            <input type="text" className="not_edit" value={ key_3.link } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                                                                                        }
                                                                                                        <span className="name_input">Link</span>
                                                                                                    </>:
                                                                                                    <>   
                                                                                                        {
                                                                                                            lock_ == 0 ?                                                                                                            
                                                                                                            <Select className="space_div" defaultOptions options={ ListPortfolios() } onChange={ (e)=> { HandleLink(index, index_1, index_2, index_3, 'portfolio_id', e.value); HandleLink(index, index_1, index_2, index_3, 'portfolio_name', e.label); HandleLink(index, index_1, index_2, index_3, 'icon', e.icon); } } value={ { "value": key_3.portfolio_id, "label": key_3.portfolio_name, "icon": key_3.icon } } styles={ { singleValue: (base) => ({ ...base, display: "flex", alignItems: "center" }) }} components={ { Option, SingleValue } } placeholder="..." />
                                                                                                            :
                                                                                                            <input type="text" className="space_div not_edit" value={ key_3.portfolio_name } onClick={ ()=>{ InfStatusBudget() } } readOnly />
                                                                                                        }
                                                                                                        <span className="name_input">Lista dos Portfolios</span>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                lock_ == 1 ? null :
                                                                                                <>
                                                                                                    <div className="div_input" onClick={ (e)=>{ RemuveLink(key_3.id, index, index_1, index_2, index_3) } } style={ { display: 'flex' } } data-tooltip-id={ "seta_delete_link_" + index_3 } data-tooltip-content="Deletar link" data-tooltip-place="top">
                                                                                                        <SvgDelete color="#f00000" className="icons" />                                                                                                    
                                                                                                    </div>
                                                                                                    <Tooltip id={ "seta_delete_link_" + index_3 } />
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                lock_ == 1 ? null :
                                                <div className="list_input_data">
                                                    <div className="div_input space_div" />
                                                    <div className="div_input">
                                                        <div className="new_data_page" onClick={ ()=>{ AddNewServices(key.id, index) } }>
                                                            <span className="icons_menu">+</span> serviço
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>                                        
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        lock_ == 1 ? null :
                        <div className="list_input_data" style={ { marginTop: "20px" } }>
                            <div className="list_services">
                                <div className="new_add_bloco" onClick={ ()=>{ NewBlockService() } }>
                                    <SvgAddData color="#F00000" className="icons" />
                                    Novo grupo
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="show_page_data"> 
                    <div className="list_type_data">
                        <div className="type_title">
                            <SvgText color="#2D3C53" className="icons" />
                            Texto fixo
                        </div>
                        <div className="type_remuve_text">
                            {
                                lock_ == 1 ? null :
                                <>
                                    <div className="" onClick={ ()=>{ DeleteAllText() } } style={ { display: "flex" } } data-tooltip-id="deletar_all_text" data-tooltip-content="Deletar todos os textos" data-tooltip-place="top">
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                    <Tooltip id="deletar_all_text" />
                                    <div className="new_data_page" onClick={ ()=>{ setTextFixed([...textFixed, { "id": 0, "type": "newText", "title": "", "text": "" }]) } }>
                                        <span className="icons_menu">+</span> texto
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {
                        textFixed.map((key, index)=>{
                            return(
                                <div className="list_input_data" key={ index }>  
                                    <div className="div_input space_div show_data_text">
                                        <div className="list_type_service">
                                            <div className="list_input_data">  
                                                <div className="div_input space_div">
                                                    {
                                                        lock_ == 0 ?
                                                        <select onChange={ (e)=>{ HandleDataText('type', index, e.target.value) }} value={ key.type }>
                                                            <option value="newText">Novo texto</option>
                                                            {
                                                                showTextFixed.map((key_1, index_1)=>{
                                                                    return(
                                                                        <option value={ key_1.id } key={ index_1 }>{ key_1.title }</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        :      
                                                        <input type="text" className="not_edit" onChange={ (e)=>{ InfStatusBudget() } } value={ key.type } readOnly />
                                                    }
                                                    <span className="name_input">Tipo de texto</span>
                                                </div>
                                                {
                                                    lock_ == 1 ? null :
                                                    <>
                                                        <div className="div_input" onClick={ ()=>{ DeleteText(key.id, index, key.title) } } style={ { display: "flex" } } data-tooltip-id={ "deletar_text_" + index } data-tooltip-content="Deletar bloco" data-tooltip-place="top">
                                                            <SvgDelete color="#f00000" className="icons" />
                                                        </div>
                                                        <Tooltip id={ "deletar_text_" + index } />
                                                    </>
                                                }
                                            </div>
                                            <div className="list_input_data">  
                                                <div className="div_input space_div">
                                                    {
                                                        lock_ == 0 ?
                                                        <input type="text" onChange={ (e)=>{ HandleDataText('title', index, e.target.value) } } value={ key.title } />
                                                        :      
                                                        <input type="text" className="not_edit" onChange={ (e)=>{ InfStatusBudget() } } value={ key.title } readOnly />
                                                    }
                                                    <span className="name_input">Título</span>
                                                </div>
                                            </div>
                                            <div className="list_input_data">  
                                                <div className="div_input space_div">
                                                    {
                                                        lock_ == 0 ?
                                                        <JoditEditor config={ config } value={ key.text ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataText('text', index, newContent) } />
                                                        :      
                                                        <div className="text_ not_edit" onClick={ ()=>{ InfStatusBudget() } }dangerouslySetInnerHTML={ { __html: key.text ? key.text.replaceAll('&#34;', '"') : "" } } />
                                                    }
                                                </div>
                                            </div>
                                        </div>                                 
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <PopUP_SaveBudget EditLock={ EditLock } />
            </form>
            <PopUP_HistoricBudget />
            <PopUP_Tasks />
            <PopUP_SalesUpdates />
            <PopUP_SendEmail />
        </div>
    )
}